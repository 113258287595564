import React, { FC, PropsWithChildren } from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

type Props = {
  title?: string;
  className?: string;
};

const Screen: FC<PropsWithChildren<Props>> = ({ children, title, className }) => {
  const { t } = useTranslation();

  const titleText = title ? `${t('app.meta_name')} / ${title}` : t('app.meta_name');

  return (
    <div className={className}>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      {children}
    </div>
  );
};

export default Screen;
