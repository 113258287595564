import { AnyZodObject, z } from 'zod';
import { ChestToken, ItemToken, PirateToken, ShipToken } from '../types/token.types.ts';

const TOKEN_SCHEMA = z.object({
  image: z.string(),
  id: z.number(),
  attributes: z.array(
    z.object({
      trait_type: z.string(),
      value: z.union([z.string(), z.number()]),
    }),
  ),
});

const DEFAULT_ATTRIBUTES_SCHEMA = z.object({
  rarity: z.enum(['common', 'rare', 'legendary', 'epic']),
});

const PIRATE_ATTRIBUTES_SCHEMA = z.object({
  navigation: z.number(),
  combat: z.number(),
  strategy: z.number(),
  mercy: z.number(),
});

const ITEM_ATTRIBUTES_SCHEMA = z.object({
  navigation: z.number(),
  combat: z.number(),
  strategy: z.number(),
  speed: z.number(),
  armament: z.number(),
  mercy: z.number(),
});

const SHIP_ATTRIBUTES_SCHEMA = z.object({
  speed: z.number(),
  armament: z.number(),
  capacity: z.number(),
});

const normalizeToken = <T extends AnyZodObject>(token: unknown, attributesSchema: T) => {
  const parsedToken = TOKEN_SCHEMA.parse(token);

  const attributesAsObject = parsedToken.attributes.reduce(
    (acc: Record<string, string | number>, attribute: { trait_type: string; value: string | number }) => {
      // eslint-disable-next-line prettier/prettier
      acc[attribute.trait_type.toLowerCase()] = typeof attribute.value === 'string' ? attribute.value.toLowerCase() : attribute.value;
      return acc;
    },
    {},
  );

  const parsedAttributes = DEFAULT_ATTRIBUTES_SCHEMA.merge(attributesSchema).parse(attributesAsObject);

  return {
    id: parsedToken.id,
    image: parsedToken.image,
    attributes: {
      ...parsedAttributes,
    },
  };
};

export const normalizePirateToken = (token: unknown): PirateToken => normalizeToken(token, PIRATE_ATTRIBUTES_SCHEMA);

export const normalizeShipToken = (token: unknown): ShipToken => normalizeToken(token, SHIP_ATTRIBUTES_SCHEMA);

export const normalizeItemToken = (token: unknown): ItemToken => normalizeToken(token, ITEM_ATTRIBUTES_SCHEMA);

export const normalizeChestToken = (token: unknown): ChestToken => {
  const parsedToken = TOKEN_SCHEMA.parse(token);
  const imageIpfsId = parsedToken.image.split('://')[1];
  return {
    id: parsedToken.id,
    image: `https://ipfs.io/ipfs/${imageIpfsId}`,
    attributes: {},
  };
};
