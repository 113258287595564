// eslint-disable-next-line import/no-extraneous-dependencies
import { constructSimpleSDK } from '@paraswap/sdk';
import { Wallet } from '../types/auth.types.ts';
import { MAINNET_ID, getSmartContractAddressForTokenGenre } from './contracts.util.ts';

// eslint-disable-next-line import/prefer-default-export
export const getGoldConversionRate = async (wallet: Wallet): Promise<number> => {
  // Gold conversion is not supported on testnet
  if (wallet.networkId !== MAINNET_ID) {
    return 0;
  }

  const paraSwapMin = constructSimpleSDK({ chainId: wallet.networkId, fetch: window.fetch });

  const gold = await getSmartContractAddressForTokenGenre(wallet, 'gold');

  const priceRoute = await paraSwapMin.swap.getRate({
    srcToken: gold,
    srcDecimals: 18,
    destToken: 'USDT',
    amount: '1000000000000000000',
    userAddress: wallet.address,
  });

  return parseFloat(priceRoute.srcUSD);
};

export const getGoldSwapLink = async (wallet: Wallet): Promise<string | undefined> => {
  if (wallet.networkId === MAINNET_ID) {
    return 'https://app.paraswap.io/#/0xc2132D05D31c914a87C6611C10748AEb04B58e8F-0xe730341CB1129bb0a8e0011c31dAb74fae147E43/50/SELL?version=5&network=polygon';
  }
  return undefined;
};
