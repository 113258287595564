import React, { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';
import * as ethers from 'ethers';

import LayoutIcon from './LayoutIcon.component.tsx';
import useAuth from '../../blockchain/hooks/useAuth.hook.ts';
import Button from '../../app/components/Button.component.tsx';

import 'react-loading-skeleton/dist/skeleton.css';
import useMaticBalanceQuery from '../../blockchain/hooks/useMaticBalanceQuery.hook.ts';
import useFungibleTokenBalanceQuery from '../../blockchain/hooks/useFungibeTokenBalanceQuery.hook.ts';
import useGoldConversionRateQuery from '../../blockchain/hooks/useGoldConversionRateQuery.ts';
import useMaticConversionRateQuery from '../../blockchain/hooks/useMaticConversionRateQuery.ts';

import useGetGoldSwapLinkQuery from '../../blockchain/hooks/useGetGoldSwapLinkQuery.hook.ts';
import { TESTNET_ID } from '../../blockchain/utils/contracts.util.ts';

const HeaderBalanceItem: FC<{
  name: string;
  imgSrc: string;
  balance: bigint | null;
  toUsdRate?: number;
  showUsd?: boolean;
  swapLink?: string;
}> = ({ name, imgSrc, balance, toUsdRate, showUsd = false, swapLink }) => {
  const { i18n } = useTranslation();

  const unweiedBalance = useMemo(() => Number.parseFloat(ethers.formatEther(balance || BigInt(0))), [balance]);

  const formattedBalance = unweiedBalance.toLocaleString(i18n.language, {
    notation: 'compact',
    maximumFractionDigits: 2,
  });

  const formattedUsdBalance = (unweiedBalance * (toUsdRate || 0)).toLocaleString(i18n.language, {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="flex flex-row items-start  space-x-4">
      <img src={imgSrc} alt={name} className="h-8 lg:h-10" />
      <div className="flex flex-col space-y-0">
        <div className="relative w-fit">
          <span className="text-md font-bold text-white lg:text-2xl">{formattedBalance}</span>
          <div
            className={clsx(
              'absolute bottom-0 left-0 right-0 top-0 transition-all duration-1000',
              balance === null ? 'opacity-100' : 'opacity-0',
            )}
          >
            <Skeleton baseColor="#202020" highlightColor="#444" height="80%" />
          </div>
        </div>
        {showUsd && (
          <div className="flex flex-col">
            <div className="relative w-fit">
              <span className="lg:text-md text-sm font-bold text-gray-500">{formattedUsdBalance}</span>
              <div
                className={clsx(
                  'absolute bottom-0 left-0 right-0 top-0 transition-all duration-1000',
                  typeof toUsdRate === 'undefined' ? 'opacity-100' : 'opacity-0',
                )}
              >
                <Skeleton baseColor="#202020" highlightColor="#444" height="70%" />
              </div>
            </div>
            {swapLink && (
              <Button
                text={i18n.t('layout.header.swap')}
                size="tiny"
                className="my-2"
                onClick={() => window.open(swapLink, '_blank')}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const matic = useMaticBalanceQuery();
  const maticConversionRateQuery = useMaticConversionRateQuery();
  const goldConversionRateQuery = useGoldConversionRateQuery();
  const gold = useFungibleTokenBalanceQuery('gold');
  const rum = useFungibleTokenBalanceQuery('rum');
  const goldSwapLink = useGetGoldSwapLinkQuery();

  const simplifiedAddress = useMemo(
    () => (auth.wallet ? `${auth.wallet.address.slice(0, 5)}...${auth.wallet.address.slice(-3)}` : null),
    [auth.wallet],
  );

  return (
    <nav className="flex w-full flex-row flex-wrap items-start justify-between md:space-x-8">
      <a
        className="flex flex-row items-center space-x-4"
        href="https://www.7seas.quest/"
        target="_blank"
        rel="noreferrer"
      >
        <LayoutIcon src="/assets/images/jolly-roger@full.png" alt={t('layout.icon.app')} />
        <span className="font-heading text-2xl text-white md:text-4xl">{t('app.name')}</span>
        {auth.wallet?.networkId === TESTNET_ID && <div className="text-sm font-bold text-brand">TESTNET</div>}
      </a>
      {simplifiedAddress && (
        <div className="md:order-3">
          <Button
            text={simplifiedAddress}
            onClick={() => {
              window.ethereum
                ?.request({
                  method: 'wallet_requestPermissions',
                  params: [{ eth_accounts: {} }],
                })
                .catch(console.error); // eslint-disable-line no-console
            }}
            rounded
          />
        </div>
      )}
      {auth.wallet && (
        <div className="mt-8 flex basis-full flex-row items-start justify-between space-x-4 md:mt-0 md:basis-auto lg:space-x-16">
          <HeaderBalanceItem
            name={t('layout.header.currency.matic')}
            imgSrc="/assets/images/matic.png"
            balance={typeof matic.data !== 'undefined' ? matic.data : null}
            showUsd
            toUsdRate={maticConversionRateQuery.data}
          />
          <HeaderBalanceItem
            name={t('layout.header.currency.gold')}
            imgSrc="/assets/images/gold.png"
            balance={typeof gold.data !== 'undefined' ? gold.data : null}
            showUsd
            toUsdRate={goldConversionRateQuery.data}
            swapLink={goldSwapLink.data}
          />
          <HeaderBalanceItem
            name={t('layout.header.currency.rum')}
            imgSrc="/assets/images/rum.png"
            balance={typeof rum.data !== 'undefined' ? rum.data : null}
          />
        </div>
      )}
    </nav>
  );
};

export default Header;
