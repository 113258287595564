import { useQuery } from 'react-query';
import { getChestUnlockTime } from '../utils/contracts.util.ts';
import useAuth from './useAuth.hook.ts';

const useGetChestUnlockTime = (id: number) => {
  const auth = useAuth();

  return useQuery(['get-chest-unlock-time', id], () => getChestUnlockTime(auth.wallet!, id), {
    select: (value) => new Date(Number(value) * 1000),
    enabled: !!auth.wallet,
  });
};

export default useGetChestUnlockTime;
