import { useQuery } from 'react-query';
import useAuth from './useAuth.hook.ts';
import { getGoldSwapLink } from '../utils/paraswap.util.ts';

const useGetGoldSwapLinkQuery = () => {
  const auth = useAuth();

  return useQuery(['gold-swap-link', auth.wallet], () => getGoldSwapLink(auth.wallet!), {
    enabled: !!auth.wallet,
  });
};

export default useGetGoldSwapLinkQuery;
