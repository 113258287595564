import { useContext } from 'react';

import AuthContext from '../contexts/Auth.context.ts';
import { AuthContextType } from '../types/auth.types.ts';

const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);
  return authContext;
};

export default useAuth;
