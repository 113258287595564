import React, { FC } from 'react';
import { NonFungibleTokenGenre } from '../../blockchain/types/token.types.ts';

type Props = {
  tokenGenre: NonFungibleTokenGenre;
  onClick?: () => void;
};

const CardBack: FC<Props> = ({ onClick, tokenGenre }) => {
  let placeholder;

  switch (tokenGenre) {
    case 'pirate':
      placeholder = (
        <image
          id="image0_2251_256"
          width="256"
          height="256"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAQAAAD2e2DtAAADtklEQVR42u3d0XHqOABAUXsnZaSRFBKKhEJoxH14//fpA9YxeuSe8+lJbAF37BmNEMsCAAAAAPx+6+wBPG7/Wj4f/NNtvf++6+ft1/1R1994/XP8M3sAzCWAOAHECSBOAHECiBNAnADiBBA3fSr4iVmzJ6ZilzOmYk+5/no5YaRPmB/APnsEc62TPwGPgDgBxAkgTgBxAogTQJwA4gQQJ4C4FwYwXlQ5+w2YbfaiUneAOAHECSBOAHECiBNAnADiBBAngLiPM066fy5fg8OPLqnkc/8eHL2v289f6pQlifv38kbfkH8bl/X28yf1CIgTQJwA4gQQJ4A4AcQJIE4AcQKIOzwTOFzAOJ4K5pj7MpgKPrq/wPEA8ut65zq6v4BHQJwA4gQQJ4A4AcQJIE4AcQKIE0DcEwH4fv/f6Oj+Au4AcQKIE0CcAOIEECeAOAHECSBOAHECiBNAnADiBBAngDgBxAkgTgBxAogTQJwA4p74cvH+Ndzs1Z6gc432B9jW+6P/bn+AN2d/AA4RQJwA4gQQJ4A4AcQJIE4AcQKIOxzAOrAc3L6UocvwvT7IHSBOAHECiBNAnADiBBAngDgBxAkg7uOUs27L6IfOx4tK+dO23IdHT3B4KvFx+3X5ft3V3trt6G+BPc4jIE4AcQKIE0CcAOIEECeAOAHECSBOAHECiBNAnADiBBAngDgBxAkgTgBxAogTQNwpi0L3bxvInuCy3o6f5L/cAeIEECeAOAHECSBOAHECiBNAnADiBBAngDgBxAkgTgBxAogTQJwA4gQQJ4A4AcQJIE4AcQKIE0CcAOIEECeAOAHECSBOAHECiBNAnADiBBAngDgBxAkgTgBxAogTQJwA4j5OOeu2jPa0/Fo+Z7/cN7Et9+HRE5yyVezYfl2+X3e1t3ZbL6+6lEdAnADiBBAngDgBxAkgTgBxAogTQJwA4gQQJ4A4AcQJIE4AcQKIE0CcAOIEECeAOAHECSBOAHECiBNAnADiBBAngDgBxAkgTgBxAogTQJwA4gQQJ4A4AcQJIE4AcQKIE0DcK3cKHW8Ve539Fkw22hN0W+9Pn+d/emEAY/s+ewRzrZM/AY+AOAHECSBOAHECiBNAnADiBBAngLjpAawDy8t+MumlLsPXOtn0AJhLAHECiBNAnADiBBAngDgBxAkg7mP2AIa25fbw387+SfrHR7pNHunQ9KnIo2YvKp0/mXuMR0CcAOIEECeAOAHECSBOAHECiBNA3N85FfyM37mAFAAAAADgB/0L0QHvXmt/T7sAAAAASUVORK5CYII="
        />
      );
      break;
    case 'ship':
      placeholder = (
        <image
          id="image0_2251_256"
          width="256"
          height="256"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAQAAAD2e2DtAAAED0lEQVR42u3d0U3jSgBAUftpy0gjKQT6oBL6gELSCH34NTBIjmaN8d5zPlFwJuYqlobxeFkAAAAAAAAAAAAAuLz17AGcbfvY/dL39XH2aPnrtv1ezh7rEf47ewCcSwBxAogTQJwA4gQQJ4A4AcQJIM5U8Db3++vFz6BvgDgBxAkgTgBxAogTQJwA4gQQJ4C4P2cP4Ff6Wiz/rBgu/9y/UvjyXALiBBAngDgBxAkgTgBxAogTQJwA4v7JqeDtvrxNHeC+fy5wfT370865+JrWse1l+bHJXKuCuTQBxAkgTgBxAogTQJwA4gQQJwCu7YmdPmcdslPo2YtSfQPECSBOAHECiBNAnADiBBAngDgBxJ2+KHQ4v/b1Lz6eabsvt50vve2fdVw/z/5ck2anQq8zFbx9HDGo2fPvEhAngDgBxAkgTgBxAogTQJwA4gQQd8hU8PT9+c8Y3Z9/zPu/jeYCR/sDfPP575Pv/37EBrbH/C/gthyygnZkNBe+LYcEsP8PeMznfxwx7+8SECeAOAHECSBOAHECiBNAnADiBBA3PRM4vyzx71s/9++AOhz/59U3gN3PN0CcAOIEECeAOAHECSBOAHECiBNA3On7A8w6ZP/O0P35lw/gkMdD3Z9YAOqhUVyZAOIEECeAOAHECSBOAHECiBNA3IVmAre36Tvs5+y/P/8x3LVgdvy79yd4xoUCWO4/t+vA0O7789evZbRrwezoD8nfJSBOAHECiBNAnADiBBAngDgBxAkgbjqAdWDJ3F3/jPV1eK5GPtfdZkflGyBOAHECiBNAnADiBBAngDgBxAkg7pg1gcM1cd/Yv1LuMfn7s+6jTUX37w+w3Zfbzpc+sT/B0Nd6wOOlDrKNPHHP/3ayJ0b68WODeuL8uQTECSBOAHECiBNAnADiBBAngDgBxA2ngrf7IY9f3+8+nMt6H05wzi5AHb3TY3mfOeQ35+/c3Q2+Mf5fwO3kO/HH7z+cdZ/dq3c4l/s1edSzz98TXALiBBAngDgBxAkgTgBxAogTQJwA4g555NH2csijnBiY3SHAN0CcAOIEECeAOAHECSBOAHECiBNAnADiBBAngDgBxAkgTgBxAogTQJwA4gQQJ4C4YxaF3ob3wr/9zjvkr212UeghAYxtH9e5a/46rApmigDiBBAngDgBxAkgTgBxAogTQJwAruN1HZg9qADiBBAngDgBxAkgTgBxAogTQJwA4v7MH2K38TPtrRM81Q8uCh3btvljRLzOPiBrxCUgTgBxAogTQJwA4gQQJ4A4AcQJIO4np4LHXgc/uy9vZw/rVI/lffjTA5wewGh6c1viAXwdMek75hIQJ4A4AcQJIE4AcQKIE0CcAOIEAAAAAAAAAAAAAAAAAAAAAPDL/Q8B8flRHwPjHwAAAABJRU5ErkJggg=="
        />
      );
      break;
    case 'item':
      placeholder = (
        <image
          id="image0_2251_256"
          width="256"
          height="256"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAQAAAD2e2DtAAABoklEQVR42u3cwW3CQBRAwSVKGTTiQpI+aIsUkrKcWy4QyZYDBt7M0ULos3rySivjMQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH7N5/lZnJf/qre9l5V9CSBOAHECiBNAnADiBBAngDgBxL0v/+g8jePl1cPX/w81H8e035L86bj9Kx7PigDGaXxcuXq4wVTTWHGYyRa2gDgBxAkgTgBxAogTQJwA4gQQJ4C4NSeBV615AHGxlzx0fUybA7h6PMzTsAXECSBOAHECiBNAnADiBBAngDgBxAkgTgBxAogTQJwA4gQQJ4A4AcQJIE4AcQKIE0CcAOIEECeAOAHECSBOAHECiBNAnADitv89/PMGU03jdP+laNocwE3eFTwEcC+2gDgBxAkgTgBxAogTQJwA4gQQJwBYZj7Pz2LF63vdAeIEECeAOAHECSBOAHECiBNAnADitj8U2vG99wAvOCkAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMBj+AGnFmHiNWPZ/QAAAABJRU5ErkJggg=="
        />
      );
      break;
    default:
      break;
  }

  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  return (
    <button type="button" onClick={onClick} style={{ cursor: onClick === undefined ? 'default' : 'pointer' }}>
      <svg
        className="h-[160px] w-[128px] md:h-[200px] md:w-[160px]"
        viewBox="0 0 436 560"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="436" height="559.349" rx="24" fill="#FFF76B" className="animate-card-color-1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M436 440.465C429.775 438.77 423.248 436.947 416.329 435.014C411.476 433.658 406.431 432.249 401.162 430.792C341.016 414.048 312.43 400.581 288.243 389.187C262.846 377.222 242.298 367.542 195.154 358.751C62.0846 333.457 0 477.314 0 477.314H436V440.465Z"
          fill="#D4CE5F"
          className="animate-card-color-2"
        />
        <path
          opacity="0.66"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M436 324.197C249.507 211.317 0 387.43 0 387.43V477.313H436V324.197Z"
          fill="#D4CE5F"
          className="animate-card-color-2"
        />
        <path
          opacity="0.33"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M436 327.727C397.101 341.295 364.14 345.52 331.479 325.323C143.272 208.34 0 342.486 0 342.486V477.309H436V327.727Z"
          fill="#D4CE5F"
          className="animate-card-color-2"
        />
        <path
          d="M0 476.314H436V535.844C436 549.099 425.255 559.844 412 559.844H24C10.7452 559.844 0 549.099 0 535.844V476.314Z"
          fill="#D4CE5F"
          className="animate-card-color-2"
        />
        <mask id="mask0_2251_256" maskUnits="userSpaceOnUse" x="62" y="131" width="311" height="320">
          <rect x="62.4482" y="131.039" width="310.044" height="319.141" fill="url(#pattern0_2251_256)" />
        </mask>
        <g mask="url(#mask0_2251_256)">
          <rect x="62.4482" y="126.057" width="310.044" height="336.594" fill="black" fillOpacity="0.6" />
        </g>
        <defs>
          <pattern id="pattern0_2251_256" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0_2251_256" transform="matrix(0.00402086 0 0 0.00390625 -0.0146703 0)" />
          </pattern>
          {placeholder}
        </defs>
      </svg>
    </button>
  );
};

export default CardBack;
