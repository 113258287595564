import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { NonFungibleTokenGenre } from '../../blockchain/types/token.types.ts';

const Odd: FC<{ name: string; odd: number }> = ({ name, odd }) => {
  const { t } = useTranslation();

  let colorClassName = 'bg-rarity-common';
  switch (name) {
    case 'legendary':
      colorClassName = 'bg-rarity-legendary';
      break;
    case 'epic':
      colorClassName = 'bg-rarity-epic';
      break;
    case 'rare':
      colorClassName = 'bg-rarity-rare';
      break;
    default:
  }

  return (
    <div className="flex flex-row space-x-2">
      <div className={`${colorClassName} h-fill w-2`} />
      <div>
        <div className="font-heading text-lg text-brand">{t(`home.token.rarity.${name}`)}</div>
        <div className="text-white">{`${(odd * 100).toFixed(1)}%`}</div>
      </div>
    </div>
  );
};

const RarityOdds: FC<{
  tokenGenre: NonFungibleTokenGenre;
}> = ({ tokenGenre }) => {
  const odds = {
    pirate: {
      legendaryOdds: 0.012,
      epicOdds: 0.043,
      rareOdds: 0.135,
      commonOdds: 0.81,
    },
    ship: {
      legendaryOdds: 0.02,
      epicOdds: 0.06,
      rareOdds: 0.138,
      commonOdds: 0.782,
    },
    item: {
      legendaryOdds: 0.006,
      epicOdds: 0.063,
      rareOdds: 0.31,
      commonOdds: 0.621,
    },
    chest: {
      legendaryOdds: 0,
      epicOdds: 0,
      rareOdds: 0,
      commonOdds: 0,
    },
  };

  const { t } = useTranslation();
  return (
    <div className="space-y-2">
      <h1 className="text-center font-heading text-2xl text-white">{t('home.token.rarity.title')}</h1>
      <div className="grid gap-4 pl-8 pr-8 md:grid-cols-2">
        <Odd name="common" odd={odds[tokenGenre].commonOdds} />
        <Odd name="rare" odd={odds[tokenGenre].rareOdds} />
        <Odd name="epic" odd={odds[tokenGenre].epicOdds} />
        <Odd name="legendary" odd={odds[tokenGenre].legendaryOdds} />
      </div>
    </div>
  );
};

export default RarityOdds;
