import { useMutation } from 'react-query';

import { transferNonFungibleToken } from '../utils/contracts.util.ts';
import useAuth from './useAuth.hook.ts';
import { NonFungibleTokenGenre } from '../types/token.types.ts';

const useTransferNonFungibleTokenMutation = () => {
  const auth = useAuth();

  return useMutation({
    mutationFn: ({
      token,
      recipient,
      id,
      quantity,
    }: {
      token: NonFungibleTokenGenre;
      recipient: string;
      id: number;
      quantity: number;
    }) => transferNonFungibleToken(auth.wallet!, token, recipient, id, quantity),
  });
};

export default useTransferNonFungibleTokenMutation;
