import React, { FC, useEffect, useState } from 'react';
import PirateTokenCard from './PirateTokenCard.component.tsx';
import CardBack from './CardBack.component.tsx';
import { NonFungibleTokenGenre } from '../../blockchain/types/token.types.ts';
import ShipTokenCard from './ShipTokenCard.component.tsx';
import ItemTokenCard from './ItemTokenCard.component.tsx';

type Props = {
  tokenGenre: NonFungibleTokenGenre;
  flipped: boolean;
  interactive: boolean;
  id: number;
};

const TokenFlipCard: FC<Props> = ({ flipped, interactive, tokenGenre, id }) => {
  const [isFlipped, setIsFlipped] = useState(flipped);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    setIsFlipped(flipped);
  }, [flipped]);

  return (
    <div
      className="relative h-[160px] w-[128px] transition-transform duration-1000 md:h-[200px] md:w-[160px]"
      style={{ transformStyle: 'preserve-3d', transform: isFlipped ? 'rotateY(2.5turn)' : undefined }}
    >
      <div className="absolute inset-0 flex items-center justify-center" style={{ backfaceVisibility: 'hidden' }}>
        <CardBack onClick={interactive ? handleFlip : undefined} tokenGenre={tokenGenre} />
      </div>
      <div
        className="absolute inset-0 flex items-center justify-center "
        style={{ backfaceVisibility: 'hidden', transform: 'rotateY(2.5turn)' }}
      >
        {tokenGenre === 'pirate' && <PirateTokenCard id={id} onClick={interactive ? handleFlip : undefined} />}
        {tokenGenre === 'ship' && <ShipTokenCard id={id} onClick={interactive ? handleFlip : undefined} />}
        {tokenGenre === 'item' && <ItemTokenCard id={id} onClick={interactive ? handleFlip : undefined} />}
      </div>
    </div>
  );
};

export default TokenFlipCard;
