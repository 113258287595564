import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../app/components/Button.component.tsx';

type Props = {
  text: string;
  buttonText: string;
  href: string;
  actions?: { title: string; onClick: () => void }[];
};

const EmptyToken: FC<Props> = ({ text, buttonText, href, actions }) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col items-center justify-center space-y-6 md:space-y-8">
      <span className="max-w-2xl whitespace-pre-line text-center text-lg text-white md:text-xl">{t(text)}</span>
      {actions?.map((action) => <Button key={action.title} text={action.title} onClick={action.onClick} />)}
      <a href={href} target="_blank" rel="noreferrer" aria-label={t(text)}>
        <Button text={t(buttonText)} image="/assets/images/open-sea.png" />
      </a>
    </div>
  );
};

export default EmptyToken;
