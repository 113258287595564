import { useQuery } from 'react-query';

import useAuth from './useAuth.hook.ts';
import { getPiratePrice } from '../utils/contracts.util.ts';

const usePiratePriceQuery = () => {
  const auth = useAuth();

  return useQuery(['pirate-price', auth.wallet], () => getPiratePrice(auth.wallet!), {
    enabled: !!auth.wallet,
    // Refresh every 30 secs
    refetchInterval: 30000,
  });
};

export default usePiratePriceQuery;
