import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAddress } from 'ethers';
import { useQueryClient } from 'react-query';
import Modal from './Modal.component.tsx';
import { NonFungibleTokenGenre, Token } from '../../blockchain/types/token.types.ts';
import CloseButton from './CloseButton.component.tsx';
import Button from './Button.component.tsx';
import TextInput from './TextInput.component.tsx';
import TokenCard from './TokenCard.component.tsx';
import Slider from './Slider.component.tsx';
import useTransferNonFungibleTokenMutation from '../../blockchain/hooks/useTransferNonFungibleTokenMutation.hook.ts';
import Spinner from './Spinner.component.tsx';
import useAuth from '../../blockchain/hooks/useAuth.hook.ts';

type Props = {
  tokenGenre: NonFungibleTokenGenre;
  token: Token;
  isOpen: boolean;
  quantity: number;
  onClose: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TransferModal: FC<Props> = ({ isOpen, onClose, tokenGenre, token, quantity }) => {
  const { t } = useTranslation();
  const [recipientAddress, setRecipientAddress] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const auth = useAuth();
  const transferNonFungibleTokenMutation = useTransferNonFungibleTokenMutation();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setRecipientAddress('');
    setSelectedQuantity(1);
    transferNonFungibleTokenMutation.reset();
    if (transferNonFungibleTokenMutation.data) {
      queryClient.resetQueries(['owned-non-fungible-tokens', auth.wallet, 'pirate']);
    }
    onClose();
  };

  const renderInitialState = () => (
    <div className="flex h-full flex-col items-center">
      <TokenCard token={token} id={token.id} quantity={quantity} />
      <div className="mt-8 flex w-3/4 flex-col space-y-4 md:w-1/2 ">
        <TextInput
          text={recipientAddress}
          placeholder={t('home.token.transfer.recipient_address')}
          onChangeText={(text) => setRecipientAddress(text)}
        />
        {quantity > 1 && (
          <Slider
            label={t('home.token.transfer.quantity')}
            value={selectedQuantity}
            min={1}
            max={quantity}
            onChangeValue={(value) => setSelectedQuantity(value)}
          />
        )}
      </div>
      <div className="flex grow flex-row items-end justify-center">
        <Button
          className="align-bottom"
          text={t('home.token.actions.transfer')}
          image="/assets/images/send.png"
          onClick={() =>
            transferNonFungibleTokenMutation.mutate({
              token: tokenGenre,
              recipient: recipientAddress,
              id: token.id,
              quantity: selectedQuantity,
              // eslint-disable-next-line prettier/prettier
            })}
          disabled={recipientAddress.length === 0 || !isAddress(recipientAddress)}
        />
      </div>
    </div>
  );

  const renderLoadingState = () => (
    // eslint-disable-next-line prettier/prettier
    <div className=" flex h-full animate-fade-in items-center justify-items-center">
      <div className="flex flex-grow flex-col items-center space-y-4">
        <h1 className="text-center font-heading text-3xl text-brand md:text-6xl">
          {t('home.token.transfer.loading.title')}
        </h1>
        <p className="text-md mb-12 text-center text-gray-500 md:text-lg lg:text-xl">
          {t('home.token.transfer.loading.subtitle')}
        </p>
        <Spinner />
      </div>
    </div>
  );

  const renderCompletedState = () => (
    // eslint-disable-next-line prettier/prettier
    <div className="flex h-full animate-fade-in items-center justify-items-center">
      <div className="flex h-full flex-grow flex-col items-center space-y-4">
        <div className="grow content-center">
          <h1 className="text-center align-middle font-heading text-3xl text-brand md:text-6xl">
            {t('home.token.transfer.completed')}
          </h1>
        </div>
        <div className="flex flex-row items-end justify-center">
          <Button className="align-bottom" text="Ok" onClick={() => handleClose()} />
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose()}
      className="h-[28rem] md:h-[30rem] md:!w-[56rem]"
      hideCloseButton
    >
      <div className="flex h-6 w-full flex-row justify-end">
        <CloseButton onClick={() => handleClose()} />
      </div>

      {!transferNonFungibleTokenMutation.isLoading && !transferNonFungibleTokenMutation.data && renderInitialState()}
      {transferNonFungibleTokenMutation.isLoading && !transferNonFungibleTokenMutation.isError && renderLoadingState()}
      {transferNonFungibleTokenMutation.data && !transferNonFungibleTokenMutation.isError && renderCompletedState()}
    </Modal>
  );
};

export default TransferModal;
