import { useQuery } from 'react-query';

import { getOwnedNonFungibleTokens } from '../utils/contracts.util.ts';
import { NonFungibleTokenGenre } from '../types/token.types.ts';
import useAuth from './useAuth.hook.ts';

const useOwnedNonFungibleTokensQuery = (token: NonFungibleTokenGenre, enabled: boolean) => {
  const auth = useAuth();

  return useQuery(
    ['owned-non-fungible-tokens', auth.wallet, token],
    () => getOwnedNonFungibleTokens(auth.wallet!, token),
    { enabled: !!auth.wallet && enabled },
  );
};

export default useOwnedNonFungibleTokensQuery;
