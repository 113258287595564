import { useQuery } from 'react-query';

import { getChestTokenInfo, getTokenInfo } from '../utils/contracts.util.ts';
import {
  normalizeChestToken,
  normalizeItemToken,
  normalizePirateToken,
  normalizeShipToken,
} from '../utils/token-normalize.util.ts';
import useAuth from './useAuth.hook.ts';

export const useGetPirateTokenInfoQuery = (id: number) => {
  const auth = useAuth();

  return useQuery(['get-pirate-token-info', id], () => getTokenInfo(auth.wallet!, id, 'pirate'), {
    select: normalizePirateToken,
    enabled: !!auth.wallet,
  });
};

export const useGetShipTokenInfoQuery = (id: number) => {
  const auth = useAuth();

  return useQuery(['get-ship-token-info', id], () => getTokenInfo(auth.wallet!, id, 'ship'), {
    select: normalizeShipToken,
    enabled: !!auth.wallet,
  });
};

export const useGetItemTokenInfoQuery = (id: number) => {
  const auth = useAuth();

  return useQuery(['get-item-token-info', id], () => getTokenInfo(auth.wallet!, id, 'item'), {
    select: normalizeItemToken,
    enabled: !!auth.wallet,
  });
};

export const useGetChestTokenInfoQuery = (id: number) => {
  const auth = useAuth();

  return useQuery(['get-chest-token-info', id], () => getChestTokenInfo(auth.wallet!, id), {
    select: normalizeChestToken,
    enabled: !!auth.wallet,
  });
};
