// eslint-disable-file  import/prefer-default-expor

import * as ethers from 'ethers';

import { ConnectWalletError, Wallet } from '../types/auth.types.ts';

export const connectToWallet = async (): Promise<Wallet | null> => {
  if (!window.ethereum) {
    throw ConnectWalletError.NO_WALLET_AVAILABLE;
  }

  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const networkId = Number((await provider.getNetwork()).chainId);

  return { address: await signer.getAddress(), networkId, provider };
};

export const silentConnectToWallet = async (): Promise<Wallet | null> => {
  if (window.ethereum) {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const accounts = await provider.listAccounts();
    const networkId = Number((await provider.getNetwork()).chainId);

    if (accounts.length > 0) {
      return { address: accounts[0].address, networkId, provider }; // 0xeCF89A2D303a837Ff2D81f17a25940AfbE41EF68 // 0xbafEa82Eb6886b43B3b4Fc0d162902DCF944Ed09
    }
  }

  return null;
};
