import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ethers } from 'ethers';
import Skeleton from 'react-loading-skeleton';

const TokenPrice: FC<{
  name: string;
  imgSrc: string;
  toUsdRate?: number;
  amount?: bigint;
}> = ({ name, imgSrc, amount, toUsdRate }) => {
  const { i18n } = useTranslation();

  const unweiedAmount = useMemo(() => Number.parseFloat(ethers.formatEther(amount || BigInt(0))), [amount]);

  const formattedAmount = unweiedAmount.toLocaleString(i18n.language, {
    notation: 'standard',
    maximumFractionDigits: 2,
  });

  const formattedUsdBalance = (unweiedAmount * (toUsdRate || 0)).toLocaleString(i18n.language, {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className="flex flex-row  justify-between">
      <div className="flex flex-row items-center space-x-2">
        <img src={imgSrc} alt={name} className="h-8 lg:h-10" />
        <div className="flex flex-row space-y-0">
          <div className="relative w-fit">
            <span className="text-lg font-bold text-white sm:text-3xl md:text-4xl">{formattedAmount}</span>
            <div
              className={clsx(
                'absolute bottom-0 left-0 right-0 top-0 transition-all duration-1000',
                amount === null ? 'opacity-100' : 'opacity-0',
              )}
            >
              <Skeleton baseColor="#202020" highlightColor="#444" height="80%" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="relative w-fit">
          <span className="text-lg font-bold text-gray-500 sm:text-3xl md:text-4xl">{formattedUsdBalance}</span>
          <div
            className={clsx(
              'absolute bottom-0 left-0 right-0 top-0 transition-all duration-1000',
              typeof toUsdRate === 'undefined' ? 'opacity-100' : 'opacity-0',
            )}
          >
            <Skeleton baseColor="#202020" highlightColor="#444" height="70%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenPrice;
