import React, { FC } from 'react';

import { useGetPirateTokenInfoQuery } from '../../blockchain/hooks/useGetTokenInfoQuery.hook.ts';
import TokenCardWithAttributeModal from './TokenCardWithAttributeModal.component.tsx';
import useOpenSeaCollectionUrlsQuery from '../../blockchain/hooks/useOpenSeaCollectionUrlsQuery.hook.ts';

type Props = {
  id: number;
  quantity?: number;
  mini?: boolean;
  onClick?: () => void;
};

const PirateTokenCard: FC<Props> = ({ id, mini = false, quantity, onClick }) => {
  const tokenQuery = useGetPirateTokenInfoQuery(id);
  const openSeaCollectionUrlsQuery = useOpenSeaCollectionUrlsQuery();

  return (
    <TokenCardWithAttributeModal
      tokenGenre="pirate"
      token={tokenQuery.data}
      id={id}
      mini={mini}
      quantity={quantity}
      onClick={onClick}
      collectionUrl={`${openSeaCollectionUrlsQuery.data?.pirate.itemPath}/${id}`}
    />
  );
};

export default PirateTokenCard;
