import React, { type FC } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';

import Router from '../../routing/components/Router.component.tsx';
import Layout from '../../layout/components/Layout.component.tsx';
import AuthProvider from '../../blockchain/components/AuthProvider.component.tsx';

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } } });

const App: FC = () => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Layout>
          <Router />
        </Layout>
      </AuthProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

export default App;
