import React from 'react';
import ReactDOM from 'react-dom/client';
// import { ReportHandler } from 'web-vitals';

import './index.css';

import App from './app/components/App.component.tsx';
import { initI18n } from './i18n/utils/i18n.util.ts';

/* const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
}; */

// Init i18n
initI18n();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// reportWebVitals(console.table);
