import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import Modal from './Modal.component.tsx';
import Button from './Button.component.tsx';
import PirateTokenCard from './PirateTokenCard.component.tsx';
/* import { useSpring, animated } from 'react-spring';
import Button from '../../app/components/Button.component.tsx';
import PirateTokenCard from './PirateTokenCard.component.tsx'; */
import ShipTokenCard from './ShipTokenCard.component.tsx';
import useOpenMysteryChestMutation from '../../blockchain/hooks/useOpenMysteryChestMutation.hook.ts';
import useAuth from '../../blockchain/hooks/useAuth.hook.ts';
import Spinner from './Spinner.component.tsx';
import CloseButton from './CloseButton.component.tsx';
import AbsolutelyCenteredContainer from './AbsolutelyCenteredContainer.component.tsx';

type Props = {
  id: number;
  isOpen: boolean;
  onClose: () => void;
};

// eslint-disable-next-line no-shadow
enum AnimationStateName {
  Iddle,
  Openable,
  Loading,
  Opened,
  TokenDisplayed,
}

type AnimationState =
  | { name: AnimationStateName.Iddle }
  | { name: AnimationStateName.Openable }
  | { name: AnimationStateName.Loading }
  | { name: AnimationStateName.Opened; pirate1: number; pirate2: number; ship: number }
  | { name: AnimationStateName.TokenDisplayed; pirate1: number; pirate2: number; ship: number };

const AppearingTokenCardWrapper: FC<
  PropsWithChildren<{ appearsFrom: 'top-left' | 'top' | 'top-right'; visible: boolean; classNames?: string }>
> = ({ appearsFrom, children, visible, classNames }) => {
  const delay = {
    'top-left': 'delay-[3500ms]',
    top: 'delay-[2500ms]',
    'top-right': 'delay-[1500ms]',
  }[appearsFrom];

  return (
    <div
      className={clsx(
        'transition-all duration-1000',
        delay,
        visible ? 'translate-y-0 opacity-100' : clsx('-translate-y-1/2 opacity-0'),
        classNames,
      )}
    >
      {children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OpenChestModal: FC<Props> = ({ id, isOpen, onClose }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const openMysteryChestMutation = useOpenMysteryChestMutation();

  const [animationState, setAnimationState] = useState<AnimationState>({ name: AnimationStateName.Iddle });

  const chestContainerClassNames = useMemo(() => {
    const topClassName = [
      AnimationStateName.Opened,
      AnimationStateName.TokenDisplayed,
      AnimationStateName.Loading,
    ].includes(animationState.name)
      ? 'top-16'
      : 'top-full';

    return clsx(
      'absolute relative left-0 right-0 my-auto flex -translate-y-1/2 justify-center transition-all duration-1000',
      topClassName,
    );
  }, [animationState]);

  const blurredCircleClassNames = useMemo(() => {
    const scaleClasses = animationState.name === AnimationStateName.Iddle ? 'scale-100' : 'scale-[50] scale-[75]';
    return clsx('rounded-full bg-brand h-1 w-1 transition-all duration-[3000ms] ease-out', scaleClasses);
  }, [animationState]);

  const lightrayClassNames = useMemo(() => {
    const scaleClasses = animationState.name === AnimationStateName.Iddle ? 'scale-100' : 'scale-[50] md:scale-[75]';
    return clsx('rounded-full h-1 w-1 transition-all duration-[3000ms] ease-out', scaleClasses);
  }, [animationState]);

  const closedMysteryChestClassNames = useMemo(() => {
    const wiggleClasses = [
      AnimationStateName.Opened,
      AnimationStateName.TokenDisplayed,
      AnimationStateName.Loading,
    ].includes(animationState.name)
      ? ''
      : 'animate-chest-wiggle';

    const opacityClasses = [AnimationStateName.Opened, AnimationStateName.TokenDisplayed].includes(animationState.name)
      ? 'opacity-0'
      : 'opacity-100';
    return clsx('h-20 w-20 md:h-28 md:w-28 transition-opacity duration-1000', wiggleClasses, opacityClasses);
  }, [animationState]);

  const openedMysteryChestClassNames = useMemo(() => {
    const opacityClasses = [AnimationStateName.Opened, AnimationStateName.TokenDisplayed].includes(animationState.name)
      ? 'opacity-100'
      : 'opacity-0';
    return clsx('h-20 w-20 md:h-28 md:w-28 transition-opacity duration-1000', opacityClasses);
  }, [animationState]);

  const buttonContainerClassNames = useMemo(() => {
    const opacityClasses = animationState.name !== AnimationStateName.Iddle ? 'opacity-100' : 'opacity-0';

    return clsx(
      'mt-12 flex w-full flex-row items-center justify-center transition-all delay-[2000ms] duration-1000',
      opacityClasses,
    );
  }, [animationState]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setAnimationState({ name: AnimationStateName.Openable }));
    }
  }, [isOpen]);

  useEffect(() => {
    if (animationState.name === AnimationStateName.Opened) {
      setTimeout(() => setAnimationState({ ...animationState, name: AnimationStateName.TokenDisplayed }));
    }
  }, [animationState]);

  useEffect(() => {
    if (openMysteryChestMutation.data && animationState.name === AnimationStateName.Loading) {
      queryClient.resetQueries(['owned-non-fungible-tokens', auth.wallet, 'pirate']);
      queryClient.resetQueries(['owned-non-fungible-tokens', auth.wallet, 'ship']);
      setAnimationState({ name: AnimationStateName.Opened, ...openMysteryChestMutation.data });
    } else if (openMysteryChestMutation.isError && animationState.name === AnimationStateName.Loading) {
      alert(t('home.token.chest.open.error')); // eslint-disable-line no-alert
      console.error(openMysteryChestMutation.error); // eslint-disable-line no-console
      setAnimationState({ name: AnimationStateName.Openable });
    }
  }, [
    t,
    openMysteryChestMutation.data,
    openMysteryChestMutation.isError,
    openMysteryChestMutation.error,
    auth.wallet,
    animationState,
    queryClient,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} className="md:!w-[56rem]" hideCloseButton>
      <div className="flex h-6 w-full flex-row justify-end">
        {[AnimationStateName.Iddle, AnimationStateName.Openable].includes(animationState.name) && (
          <CloseButton onClick={() => onClose()} />
        )}
      </div>

      <div className="relative h-48 w-full">
        <div className={chestContainerClassNames}>
          <AbsolutelyCenteredContainer classNames="blur-3xl">
            <div className={blurredCircleClassNames} />
          </AbsolutelyCenteredContainer>

          <AbsolutelyCenteredContainer classNames="blur animate-spin-slow">
            <img src="/assets/images/mystery-chest-closed-bg.png" className={lightrayClassNames} alt="Light rays" />
          </AbsolutelyCenteredContainer>

          <AbsolutelyCenteredContainer>
            <img
              className={closedMysteryChestClassNames}
              src="/assets/images/mystery-chest-closed.png"
              alt="Closed mystery chest"
            />
          </AbsolutelyCenteredContainer>

          <AbsolutelyCenteredContainer>
            <img
              className={openedMysteryChestClassNames}
              src="/assets/images/mystery-chest-open.png"
              alt="Closed mystery chest"
            />
          </AbsolutelyCenteredContainer>
        </div>
      </div>

      <div className="flex h-[228px] w-full flex-row flex-wrap items-center justify-around md:h-[200px] md:flex-nowrap">
        {animationState.name === AnimationStateName.Loading && <Spinner />}

        {/* eslint-disable-next-line prettier/prettier */}
        {(animationState.name === AnimationStateName.Opened || animationState.name === AnimationStateName.TokenDisplayed) && (
          <>
            <AppearingTokenCardWrapper
              visible={animationState.name === AnimationStateName.TokenDisplayed}
              appearsFrom="top-right"
            >
              <PirateTokenCard id={animationState.pirate1} mini />
            </AppearingTokenCardWrapper>
            <AppearingTokenCardWrapper
              visible={animationState.name === AnimationStateName.TokenDisplayed}
              appearsFrom="top"
            >
              <PirateTokenCard id={animationState.pirate2} mini />
            </AppearingTokenCardWrapper>
            <div className="flex h-1 basis-full md:hidden" />
            <AppearingTokenCardWrapper
              visible={animationState.name === AnimationStateName.TokenDisplayed}
              appearsFrom="top-left"
              classNames="mt-4 md:mt-0"
            >
              <ShipTokenCard id={animationState.ship} mini />
            </AppearingTokenCardWrapper>
          </>
        )}
      </div>

      <div className={buttonContainerClassNames}>
        <Button
          onClick={
            () => {
              if ([AnimationStateName.Opened, AnimationStateName.TokenDisplayed].includes(animationState.name)) {
                queryClient.setQueryData(['owned-non-fungible-tokens', auth.wallet, 'chest'], (oldData: unknown) => {
                  if (Array.isArray(oldData)) {
                    return oldData.filter((chestId) => chestId !== id);
                  }
                  return oldData;
                });
                onClose();
              } else if (auth.wallet) {
                openMysteryChestMutation.mutate({ id });
                setAnimationState({ name: AnimationStateName.Loading });
              }
            }
            // eslint-disable-next-line react/jsx-curly-newline
          }
          disabled={animationState.name === AnimationStateName.Loading}
          text={
            [AnimationStateName.Opened, AnimationStateName.TokenDisplayed].includes(animationState.name) ? 'Ok' : 'Open'
          }
        />
      </div>
    </Modal>
  );
};

export default OpenChestModal;
