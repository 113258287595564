import { useQuery } from 'react-query';

const useMaticConversionRateQuery = () =>
  useQuery(
    'matic-conversion-rate',
    () =>
      fetch('https://api.blockchain.com/v3/exchange/tickers/MATIC-USD')
        .then((res) => res.json())
        .then((payload) => payload.last_trade_price),
    {
      // Refresh every 30 secs
      refetchInterval: 30000,
    },
  );

export default useMaticConversionRateQuery;
