import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

type Props = {
  large?: boolean;
  className?: string;
};

const Container: FC<PropsWithChildren<Props>> = ({ large = false, children, className }) => (
  <div className={clsx('mx-auto', large ? 'max-w-screen-2xl' : 'max-w-screen-xl', className)}>{children}</div>
);

export default Container;
