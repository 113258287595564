import React, { FC, useMemo } from 'react';
import clsx from 'clsx';

type Props = {
  size?: 'large' | 'medium' | 'small' | 'tiny';
  rounded?: boolean;
  text: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  image?: string;
  highlight?: boolean;
  imageOnly?: boolean;
};

const Button: FC<Props> = ({
  text,
  onClick = () => undefined,
  size = 'medium',
  rounded = false,
  className,
  disabled = false,
  highlight = false,
  imageOnly = false,
  image,
}) => {
  const buttonClassName = useMemo(() => {
    const sizeClassName = {
      large: 'py-3 px-6 md:py-4 md:px-12',
      medium: 'py-1 px-4 md:py-3 md:px-8',
      small: 'py-2 px-2',
      tiny: 'py-0 px-1',
    }[size];

    const roundedClassName = rounded ? 'rounded-full' : 'rounded';

    const disabledClassName = disabled ? 'opacity-75' : 'hover:bg-brand-light opacity-100';

    const highlightClassName = highlight ? 'animate-gold-pulse' : '';

    return clsx(
      'bg-brand flex flex-row justify-center items-center space-x-2 md:space-x-2',
      sizeClassName,
      roundedClassName,
      disabledClassName,
      highlightClassName,
      className, // Ensure this is last so it can override default styles
    );
  }, [size, rounded, disabled, highlight, className]);

  const spanClassName = useMemo(() => {
    const sizeClassName = {
      large: 'text-lg md:text-xl',
      medium: 'text-md md:text-lg',
      small: 'text-sm',
      tiny: 'text-xs',
    }[size];

    return clsx('font-bold uppercase text-white', sizeClassName);
  }, [size]);

  const imageClassName = useMemo(() => {
    const sizeClassName = {
      large: 'h-12 w-12',
      medium: 'h-4 w-4 md:h-6 md:w-6',
      small: 'h-4 w-4',
      tiny: 'h-4 w-4',
    }[size];

    return clsx(sizeClassName);
  }, [size]);

  return (
    <button type="button" disabled={disabled} onClick={disabled ? undefined : onClick} className={buttonClassName}>
      {image && <img src={image} alt={text} className={imageClassName} />}
      {!imageOnly && <span className={spanClassName}>{text}</span>}
    </button>
  );
};

export default Button;
