import React, { type FC } from 'react';

const Background: FC = () => {
  const wavePaths = [
    // Stack 1
    {
      className: 'bottom-28 md:bottom-48 h-24 md:h-36 opacity-20',
      path: 'M1859.96 56.2881C1744.66 56.2881 1664.33 130.992 1532.82 182.19C1460.34 210.204 1151.96 214.712 889.181 56.2881C626.405 -102.136 656.813 167.056 511.108 56.2881C293.184 -107.932 0 144.838 0 144.838V272.994H2534V155.464C2534 155.464 2462.54 215.034 2300.62 215.034C2053.05 214.712 1965.62 56.2881 1859.96 56.2881Z',
    },
    {
      className: 'bottom-28 md:bottom-48 h-16 md:h-24 opacity-30',
      path: 'M1941.3 96.9361C1434.5 282.086 1267.25 -113.974 940.114 34.1461C613.228 184.198 613.228 172.606 468.283 123.662C324.352 74.7181 335.248 45.4161 227.807 20.9441C72.4724 -15.1199 0 189.994 0 189.994H2534C2534 189.994 2508.91 58.2961 2322.16 35.1121C2135.4 11.9281 2102.21 38.6541 1941.3 96.9361Z',
    },
    {
      className: 'bottom-28 md:bottom-48 h-20 md:h-28 opacity-40',
      path: 'M1198.34 105.727L1198.34 105.726C1069.22 34.6735 963.042 6.0029 874.144 1.23303C785.249 -3.53668 713.604 15.5909 653.55 40.1836C623.519 52.4814 596.388 66.1454 571.439 78.8761C569.743 79.7415 568.057 80.6026 566.381 81.4587C543.407 93.1928 522.275 103.986 502.437 111.956C481.15 120.507 461.311 125.825 442.233 125.559C423.141 125.293 404.841 119.434 386.64 105.687L386.639 105.686C276.942 22.403 180.392 28.5312 111.266 55.4416C76.6887 68.9021 48.9615 87.5679 29.8774 102.871C20.3361 110.522 12.9574 117.33 7.9654 122.224C5.46948 124.671 3.57039 126.64 2.29618 127.995C1.65908 128.673 1.17821 129.198 0.857072 129.552C0.699403 129.726 0.580241 129.859 0.5 129.95V321.494H2533.5V130.061L2533.31 129.96C2533.01 129.801 2532.57 129.565 2531.98 129.256C2530.81 128.639 2529.08 127.731 2526.85 126.573C2522.38 124.255 2515.91 120.933 2507.89 116.915C2491.84 108.877 2469.58 98.053 2444.73 86.9074C2394.99 64.6068 2334.94 41.0519 2293.46 35.9104L2293.52 35.4467L2293.46 35.9101C2210.56 25.3121 2134.69 75.4251 2101.79 106.937L2101.79 106.94C2072.26 134.804 2019.02 181.675 1957.19 223.193C1895.38 264.702 1824.91 300.905 1760.93 307.358C1728.89 310.588 1680.77 301.242 1625.91 285.036C1571.03 268.824 1509.32 245.723 1450.08 221.389C1331.6 172.721 1222.94 119.1 1198.34 105.727Z',
    },
    // Background
    {
      className: 'bottom-0 h-28 md:h-48 opacity-60',
      path: 'M 0 0 H 2534 V 190 H 0 Z',
    },
    // Stack 2
    {
      className: 'bottom-0 h-24 md:h-36 opacity-60',
      path: 'M1198.58 105.288C681.899 -179.038 531.887 214.768 386.942 105.288C167.244 -61.5078 0 129.76 0 129.76V321.994H2534V129.76C2534 129.76 2376.64 45.7182 2293.52 35.4142C2210.41 24.7881 2134.39 75.0202 2101.45 106.576C2042.4 162.282 1888.59 293.98 1760.88 306.86C1633.16 319.74 1247.74 132.014 1198.58 105.288Z',
    },
    {
      className: 'bottom-0 h-16 md:h-24 opacity-80',
      path: 'M1859.96 56.2881C1745.66 56.2881 1664.33 130.992 1532.82 182.19C1460.34 210.204 1151.96 214.712 889.181 56.2881C626.405 -102.136 656.813 167.056 511.108 56.2881C293.184 -107.932 0 144.838 0 144.838V272.994H2534V155.464C2534 155.464 2462.54 215.034 2300.62 215.034C2053.05 214.712 1965.62 56.2881 1859.96 56.2881Z',
    },

    {
      className: 'bottom-0 h-20 md:h-28 opacity-100',
      path: 'M1198.34 105.727L1198.34 105.726C1069.21 34.6735 963.042 6.0029 874.144 1.23303C785.249 -3.53668 713.604 15.5909 653.55 40.1836C623.519 52.4814 596.388 66.1454 571.439 78.8761C569.743 79.7415 568.057 80.6026 566.381 81.4587C543.407 93.1928 522.275 103.986 502.437 111.956C481.15 120.507 461.311 125.825 442.233 125.559C423.141 125.293 404.841 119.434 386.64 105.687L386.639 105.686C276.942 22.403 180.392 28.5312 111.266 55.4416C76.6887 68.9021 48.9615 87.5679 29.8774 102.871C20.3361 110.522 12.9574 117.33 7.9654 122.224C5.46948 124.671 3.57039 126.64 2.29618 127.995C1.65908 128.673 1.17821 129.198 0.857072 129.552C0.699403 129.726 0.580241 129.859 0.5 129.95V321.494H2533.5V130.061L2533.31 129.96C2533.01 129.801 2532.57 129.565 2531.98 129.256C2530.81 128.639 2529.08 127.731 2526.85 126.573C2522.38 124.255 2515.91 120.933 2507.89 116.915C2491.84 108.877 2469.58 98.053 2444.73 86.9074C2394.99 64.6068 2334.94 41.0519 2293.46 35.9104L2293.52 35.4467L2293.46 35.9101C2210.56 25.3121 2134.69 75.4251 2101.79 106.937L2101.79 106.94C2072.26 134.804 2019.02 181.675 1957.19 223.193C1895.38 264.702 1824.91 300.905 1760.93 307.358C1728.89 310.588 1680.77 301.242 1625.91 285.036C1571.03 268.824 1509.32 245.723 1450.08 221.389C1331.6 172.721 1222.94 119.1 1198.34 105.727Z',
    },
  ];

  return (
    <>
      <div className="fixed  bottom-0 left-0 right-0 top-0 bg-background" />
      <svg
        className="fixed left-0 right-0 top-0 h-[20vh] w-full sm:h-[40vh] md:h-[60vh]"
        viewBox="0 0 2534 1389"
        preserveAspectRatio="none"
      >
        <path
          d="M2534 0H0V1389C0 1389 360.867 1193.69 404.488 1150.06C448.11 1106.44 253.797 1185.76 241.9 1106.44C230.003 1027.13 372.764 1019.19 368.798 943.846C364.833 868.497 436.213 682.107 487.765 662.278C539.318 642.45 642.423 701.936 670.182 682.107C697.941 662.278 606.732 551.238 670.182 471.923C733.631 392.608 1031.05 253.807 1074.67 321.225C1118.29 388.642 1106.39 662.278 1146.05 662.278C1185.71 662.278 1352.26 261.739 1447.43 253.807C1542.61 245.876 1613.99 610.724 1657.61 602.792C1701.23 594.861 1760.71 432.266 1863.82 440.197C1966.92 448.128 2030.37 602.792 2073.99 662.278C2117.61 721.764 2157.27 943.846 2157.27 943.846C2157.27 943.846 2319.86 848.668 2347.62 916.086C2375.38 983.503 2534 1292.83 2534 1292.83V0Z"
          fill="black"
        />
      </svg>
      {wavePaths.map(({ path, className }) => (
        <svg
          key={path}
          className={`fixed w-full fill-background-dark ${className}`}
          viewBox="0 0 2534 190"
          preserveAspectRatio="none"
        >
          <path d={path} />
        </svg>
      ))}
    </>
  );
};

export default Background;
