import { useQuery } from 'react-query';

import useAuth from './useAuth.hook.ts';

import { getCollectionUrls } from '../utils/opensea.util.ts';

const useOpenSeaCollectionUrlsQuery = () => {
  const auth = useAuth();

  return useQuery(['opensea-collection-urls', auth.wallet], () => getCollectionUrls(auth.wallet!), {
    enabled: !!auth.wallet,
  });
};

export default useOpenSeaCollectionUrlsQuery;
