import { BrowserProvider } from 'ethers';

// eslint-disable-next-line no-shadow
export enum ConnectWalletError {
  NO_WALLET_AVAILABLE,
}

export type Wallet = {
  networkId: number;
  address: string;
  provider: BrowserProvider;
};

export type AuthContextType = {
  isBooting: boolean;
  wallet: Wallet | null;
  connectToWallet: () => Promise<void>;
  disconnectWallet: () => void;
};
