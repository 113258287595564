import React, { PropsWithChildren, type FC } from 'react';

import Footer from './Footer.component.tsx';
import Header from './Header.component.tsx';
import Background from './Background.component.tsx';
import Container from './Container.component.tsx';

const Layout: FC<PropsWithChildren> = ({ children }) => (
  <div className="min-h-screen">
    <Background />
    <Container className="relative mx-auto flex min-h-screen flex-col items-stretch space-y-16 p-8" large>
      <Header />
      <Container className="flex w-full flex-grow justify-center">{children}</Container>
      <Footer />
    </Container>
  </div>
);

export default Layout;
