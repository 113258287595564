import React from 'react';

import { useTranslation } from 'react-i18next';

import LayoutIcon from './LayoutIcon.component.tsx';

const Footer = () => {
  const { t } = useTranslation();

  const icons = [
    [
      {
        altI18nKey: 'layout.icon.discord',
        src: '/assets/images/discord.png',
        href: 'https://discord.com/invite/2Hz62BVA6J',
      },
      { altI18nKey: 'layout.icon.x', src: '/assets/images/x.png', href: 'https://twitter.com/7seasquest' },
      {
        altI18nKey: 'layout.icon.telegram',
        src: '/assets/images/telegram.png',
        href: 'https://t.me/+2GqpD95xA_k2NmM0',
      },
    ],
    [
      {
        altI18nKey: 'layout.icon.play_store',
        src: '/assets/images/play-store.png',
        href: 'https://play.google.com/store/apps/details?id=quest.sevenseas.app',
      },
      {
        altI18nKey: 'layout.icon.app_store',
        src: '/assets/images/app-store.png',
        href: 'https://apps.apple.com/fr/app/wheelik/id6444166569',
      },
    ],
  ];

  return (
    <nav className="flex w-full flex-col items-center justify-between space-y-8 md:flex-row md:space-x-8 md:space-y-0">
      {icons.map((group, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="flex flex-row items-center space-x-8" key={index}>
          {group.map(({ altI18nKey, src, href }) => (
            <LayoutIcon key={altI18nKey} src={src} alt={t(altI18nKey)} href={href} />
          ))}
        </div>
      ))}
    </nav>
  );
};

export default Footer;
