import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { connectToWallet, silentConnectToWallet } from '../utils/auth.utils.ts';
import AuthContext from '../contexts/Auth.context.ts';
import { Wallet } from '../types/auth.types.ts';

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isBooting, setIsBooting] = useState<boolean>(true);
  const [wallet, setWallet] = useState<Wallet | null>(null);

  useEffect(() => {
    silentConnectToWallet().then((result) => {
      if (result) {
        setWallet(result);
      }
      setIsBooting(false);
    });
  }, []);

  const value = useMemo(
    () => ({
      wallet,
      isBooting,
      connectToWallet: () =>
        connectToWallet().then((result) => {
          if (result) {
            setWallet(result);
          }
        }),
      disconnectWallet: () => setWallet(null),
    }),
    [isBooting, wallet],
  );

  useEffect(() => {
    const onChange = () => {
      silentConnectToWallet().then((result) => {
        setWallet(result);
      });
    };

    if (wallet) {
      if (window.ethereum && 'on' in window.ethereum && typeof window.ethereum.on === 'function') {
        window.ethereum.on('chainChanged', onChange);
        window.ethereum.on('accountsChanged', onChange);
        window.ethereum.on('disconnect', onChange);
      }
    }

    return () => {
      // eslint-disable-next-line prettier/prettier
      if (window.ethereum && 'removeListener' in window.ethereum && typeof window.ethereum.removeListener === 'function') {
        window.ethereum.removeListener('chainChanged', onChange);
        window.ethereum.removeListener('accountsChanged', onChange);
        window.ethereum.removeListener('disconnect', onChange);
      }
    };
  }, [wallet]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
