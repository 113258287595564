import React, { FC, useCallback, useEffect, useState } from 'react';

import { intervalToDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';
import TokenCard from './TokenCard.component.tsx';
import Button from './Button.component.tsx';
import { useGetChestTokenInfoQuery } from '../../blockchain/hooks/useGetTokenInfoQuery.hook.ts';
import useGetChestUnlockTime from '../../blockchain/hooks/useGetChestUnlockTime.hook.ts';
import OpenChestModal from './OpenChestModal.component.tsx';

type Props = {
  id: number;
};

const getOpenableAtDistance = (now: Date, openableAt: Date) => {
  const duration = intervalToDuration({ start: now, end: openableAt });

  // eslint-disable-next-line prettier/prettier
  return ((duration.days && duration.days >= 1) || (duration.months && duration.months >= 1) || (duration.years && duration.years >= 1))
    ? `${duration.days || 0}d ${duration.hours || 0}h ${duration.minutes || 0}m`
    : `${duration.hours || 0}h ${duration.minutes || 0}m ${duration.seconds || 0}s`;
};

const ChestTokenCard: FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const tokenQuery = useGetChestTokenInfoQuery(id);
  const unlockTimeQuery = useGetChestUnlockTime(id);

  const [openableAtDistance, setOpenableAtDistance] = useState('');
  const [isOpenable, setIsOpenable] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const refreshOpenableStatus = useCallback(() => {
    if (unlockTimeQuery.data) {
      const now = new Date();

      if (now > unlockTimeQuery.data) {
        setIsOpenable(true);
      } else {
        setOpenableAtDistance(getOpenableAtDistance(now, unlockTimeQuery.data));
      }
    } else {
      setIsOpenable(false);
      setOpenableAtDistance('');
    }
  }, [unlockTimeQuery]);

  useEffect(() => {
    refreshOpenableStatus();

    const interval = setInterval(() => {
      refreshOpenableStatus();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [refreshOpenableStatus]);

  return (
    <>
      <div className="flex flex-col space-y-4">
        <TokenCard id={id} token={tokenQuery.data} />
        <Button
          text={isOpenable ? t('open') : openableAtDistance}
          size="small"
          className="w-32 md:w-40"
          disabled={!isOpenable}
          onClick={() => setModalIsOpen(true)}
        />
      </div>
      <OpenChestModal id={id} isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} />
    </>
  );
};

export default ChestTokenCard;
