import React, { type FC } from 'react';
import { Redirect, Route, Switch } from 'wouter';

import useAuth from '../../blockchain/hooks/useAuth.hook.ts';

// Screens
import LandingScreen from '../../landing/screens/Landing.screen.tsx';
import HomeScreen from '../../home/screens/Home.screen.tsx';
import { MAINNET_ID, TESTNET_ID } from '../../blockchain/utils/contracts.util.ts';

const Router: FC = () => {
  const auth = useAuth();

  if (!auth.isBooting) {
    return (
      <Switch>
        <Route path="/">
          {auth.wallet && [MAINNET_ID, TESTNET_ID].includes(auth.wallet.networkId) && <Redirect to="/home" />}
          <Route component={LandingScreen} />
        </Route>

        {auth.wallet && [MAINNET_ID, TESTNET_ID].includes(auth.wallet.networkId) && (
          <Route path="/home" component={HomeScreen} />
        )}

        <Redirect to="/" />
      </Switch>
    );
  }

  return null;
};

export default Router;
