import { useQuery } from 'react-query';

import { getGoldConversionRate } from '../utils/paraswap.util.ts';
import useAuth from './useAuth.hook.ts';

const useGoldConversionRateQuery = () => {
  const auth = useAuth();

  return useQuery(['gold-price', auth.wallet], () => getGoldConversionRate(auth.wallet!), {
    enabled: !!auth.wallet,
    // Refresh every 30 secs
    refetchInterval: 30000,
  });
};

export default useGoldConversionRateQuery;
