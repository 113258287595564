import { useQuery } from 'react-query';

import { getMaticBalance } from '../utils/contracts.util.ts';
import useAuth from './useAuth.hook.ts';

const useMaticBalanceQuery = () => {
  const auth = useAuth();
  return useQuery(['fungible-matic-balance', auth.wallet], () => getMaticBalance(auth.wallet!), {
    enabled: !!auth.wallet,
    // Refresh every 30 secs
    refetchInterval: 30000,
  });
};

export default useMaticBalanceQuery;
