import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import Modal from '../../app/components/Modal.component.tsx';
import Button from '../../app/components/Button.component.tsx';
import useGoldConversionRateQuery from '../../blockchain/hooks/useGoldConversionRateQuery.ts';
import TokenPrice from '../../app/components/TokePrice.component.tsx';
import { NonFungibleTokenGenre } from '../../blockchain/types/token.types.ts';
import CloseButton from '../../app/components/CloseButton.component.tsx';
import RarityOdds from '../../app/components/RarityOdds.component.tsx';
import usePiratePriceQuery from '../../blockchain/hooks/usePiratePriceQuery.hook.ts';
import useMintPirateMutation from '../../blockchain/hooks/useMintPirateMutation.hook.ts';
import useAuth from '../../blockchain/hooks/useAuth.hook.ts';
import Spinner from '../../app/components/Spinner.component.tsx';
import CardBack from '../../app/components/CardBack.component.tsx';
import TokenFlipCard from '../../app/components/TokenFlipCard.component.tsx';
import AbsolutelyCenteredContainer from '../../app/components/AbsolutelyCenteredContainer.component.tsx';
import useGetGoldSwapLinkQuery from '../../blockchain/hooks/useGetGoldSwapLinkQuery.hook.ts';
import useFungibleTokenBalanceQuery from '../../blockchain/hooks/useFungibeTokenBalanceQuery.hook.ts';
import useWindowDimensions from '../../app/hooks/useWindowDimensions.hook.tsx';

type Props = {
  tokenGenre: NonFungibleTokenGenre;
  isOpen: boolean;
  onClose: () => void;
};

const MintModal: FC<Props> = ({ isOpen, onClose, tokenGenre }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 770;

  const queryClient = useQueryClient();
  const auth = useAuth();

  const goldConversionRateQuery = useGoldConversionRateQuery();
  const mintPirateMutation = useMintPirateMutation();

  const tokenLabel = tokenGenre[0].toUpperCase() + tokenGenre.slice(1);

  const piratePriceQuery = usePiratePriceQuery();

  const goldSwapLinkQuery = useGetGoldSwapLinkQuery();

  const goldBalanceQuery = useFungibleTokenBalanceQuery('gold');

  const [mintedId, setMintedId] = useState<number | undefined>(undefined);
  const [isRevealed, setIsRevealed] = useState(false);

  const [hasEnoughBalance, setHasEnoughBalance] = useState(false);

  const handleClose = () => {
    setMintedId(undefined);
    setIsRevealed(false);
    mintPirateMutation.reset();
    if (mintPirateMutation.data) {
      queryClient.resetQueries(['owned-non-fungible-tokens', auth.wallet, 'pirate']);
    }
    onClose();
  };

  useEffect(() => {
    if (piratePriceQuery.data && goldBalanceQuery.data) {
      setHasEnoughBalance(goldBalanceQuery.data >= piratePriceQuery.data);
    } else {
      setHasEnoughBalance(false);
    }
  }, [piratePriceQuery.data, goldBalanceQuery.data]);

  useEffect(() => {
    if (mintPirateMutation.data) {
      setMintedId(mintPirateMutation.data);
      queryClient.refetchQueries(['fungible-token-balance', auth.wallet, 'gold']);
    } else if (mintPirateMutation.isError) {
      alert(t('home.token.pirate.mint.error')); // eslint-disable-line no-alert
    }
  }, [t, mintPirateMutation.data, mintPirateMutation.isError, mintPirateMutation.error, auth.wallet, queryClient]);

  const renderActionBlock = () => (
    <>
      <div className="flex flex-col space-y-2 md:space-y-8">
        <TokenPrice
          name="gold"
          amount={piratePriceQuery.data}
          imgSrc="/assets/images/gold.png"
          toUsdRate={goldConversionRateQuery.data}
        />
        <Button
          text={t('home.token.actions.mint')}
          onClick={() => mintPirateMutation.mutate()}
          disabled={!hasEnoughBalance}
        />
      </div>
      <div className="h-4">
        {!hasEnoughBalance && (
          <div className="flex space-x-2">
            <p className="text-red-500">{t('home.token.pirate.mint.balance.not_enough')}</p>
            {goldSwapLinkQuery.data && (
              <button type="button" onClick={() => window.open(goldSwapLinkQuery.data, '_blank')}>
                <p className="uppercase text-brand underline">{t('home.token.pirate.mint.balance.buy')}</p>
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );

  const renderInitialStateForSmallScreen = () => (
    <div className="justification-center flex flex-col items-center space-y-4">
      <h1 className="font-heading text-6xl text-brand">{tokenLabel}</h1>
      <div>
        <CardBack tokenGenre={tokenGenre} />
      </div>
      <p className="text-md mb-12 text-center text-gray-500 md:text-lg lg:text-xl">
        {t(`home.token.${tokenGenre}.mint.text`)}
      </p>
      <div className="w-full">{renderActionBlock()}</div>
    </div>
  );

  const renderInitialState = () => {
    if (isSmallScreen) return renderInitialStateForSmallScreen();

    return (
      <div className="flex flex-row">
        <div className=" justification-center m-4 flex basis-2/5 flex-col items-center space-y-4">
          <div>
            <CardBack tokenGenre={tokenGenre} />
          </div>
          <div className="flex-2">
            <RarityOdds tokenGenre={tokenGenre} />
          </div>
        </div>
        <div className="flex basis-3/5 flex-col space-y-2">
          <div className="flex-1 space-y-4">
            <h1 className="font-heading text-6xl text-brand">{tokenLabel}</h1>
            <p className="text-md mb-12 text-gray-500 md:text-lg lg:text-xl">
              {t(`home.token.${tokenGenre}.mint.text`)}
            </p>
          </div>
          {renderActionBlock()}
        </div>
      </div>
    );
  };

  const renderLoadingState = () => (
    // eslint-disable-next-line prettier/prettier
    <div className=" flex h-full animate-fade-in items-center justify-items-center">
      <div className="flex flex-grow flex-col items-center space-y-4">
        <h1 className="text-center font-heading text-3xl text-brand md:text-6xl">
          {t('home.token.pirate.mint.loading.title')}
        </h1>
        <p className="text-md mb-12 text-center text-gray-500 md:text-lg lg:text-xl">
          {t('home.token.pirate.mint.loading.subtitle')}
        </p>
        <Spinner />
      </div>
    </div>
  );

  const renderRevealState = () => (
    // eslint-disable-next-line prettier/prettier
    <div className="flex h-full animate-fade-in flex-col items-center justify-items-center">
      <AbsolutelyCenteredContainer classNames="animate-fade-in blur animate-spin-slow z-0">
        <img src="/assets/images/mystery-chest-closed-bg.png" alt="Light rays" />
      </AbsolutelyCenteredContainer>
      <div className="z-1 flex  flex-grow items-center space-y-4">
        {mintedId && <TokenFlipCard id={mintedId} flipped={isRevealed} interactive={false} tokenGenre={tokenGenre} />}
      </div>
      <Button
        text={isRevealed ? 'Ok' : t('home.token.pirate.mint.reveal.button_text')}
        className="z-10 mb-8"
        highlight={!isRevealed}
        onClick={() => (isRevealed ? handleClose() : setIsRevealed(true))}
      />
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose()}
      className="h-[35rem] md:h-[34rem] md:!w-[56rem]"
      hideCloseButton
    >
      <div className="flex h-6 w-full flex-row justify-end">
        {!mintPirateMutation.isLoading && <CloseButton onClick={() => handleClose()} />}
      </div>
      {!mintPirateMutation.isLoading && !mintPirateMutation.data && renderInitialState()}
      {mintPirateMutation.isLoading && !mintPirateMutation.isError && renderLoadingState()}
      {mintPirateMutation.data && !mintPirateMutation.isError && renderRevealState()}
    </Modal>
  );
};

export default MintModal;
