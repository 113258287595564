import { useMutation } from 'react-query';

import { openMysteryChest } from '../utils/contracts.util.ts';
import useAuth from './useAuth.hook.ts';

const useOpenMysteryChestMutation = () => {
  const auth = useAuth();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => openMysteryChest(auth.wallet!, id),
  });
};

export default useOpenMysteryChestMutation;
