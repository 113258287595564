import React, { useState, type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import Screen from '../../app/components/Screen.component.tsx';
import useOwnedNonFungibleTokensQuery from '../../blockchain/hooks/useOwnedNonFungibleTokensQuery.hook.ts';

import PirateTokenCard from '../../app/components/PirateTokenCard.component.tsx';
import ShipTokenCard from '../../app/components/ShipTokenCard.component.tsx';
import ItemTokenCard from '../../app/components/ItemTokenCard.component.tsx';
import ChestTokenCard from '../../app/components/ChestTokenCard.component.tsx';
import EmptyToken from '../components/EmptyToken.component.tsx';
import TokenContainer from '../components/TokenContainer.component.tsx';
import Spinner from '../../app/components/Spinner.component.tsx';
import MintModal from '../components/MintModal.component.tsx';
import useOpenSeaCollectionUrlsQuery from '../../blockchain/hooks/useOpenSeaCollectionUrlsQuery.hook.ts';

const HomeScreen: FC = () => {
  const { t } = useTranslation();
  const tabs = ['pirate', 'ship', 'item', 'chest'];

  const [activeTab, setActiveTab] = useState('pirate');

  const chestsQuery = useOwnedNonFungibleTokensQuery('chest', activeTab === 'chest');
  const piratesQuery = useOwnedNonFungibleTokensQuery('pirate', activeTab === 'pirate');
  const shipsQuery = useOwnedNonFungibleTokensQuery('ship', activeTab === 'ship');
  const itemsQuery = useOwnedNonFungibleTokensQuery('item', activeTab === 'item');
  const openSeaCollectionUrlsQuery = useOpenSeaCollectionUrlsQuery();

  const [mintModalIsOpen, setMintModalIsOpen] = useState(false);

  const pirateActions = useMemo(
    () => [
      {
        title: t('home.token.actions.mint'),
        onClick: () => setMintModalIsOpen(true),
        highlight: true,
      },
    ],
    [t],
  );

  const content = useMemo(() => {
    if (activeTab === 'pirate' && piratesQuery.data) {
      return piratesQuery.data.length > 0 ? (
        <TokenContainer
          title={t(`home.token.${activeTab}.title`)}
          subtitle={t(`home.token.${activeTab}.subtitle`)}
          actions={pirateActions}
        >
          {piratesQuery.data.map(([id, quantity]) => (
            <PirateTokenCard key={id} quantity={quantity} id={Number(id)} />
          ))}
        </TokenContainer>
      ) : (
        <EmptyToken
          text="home.token.pirate.empty.text"
          buttonText="home.token.pirate.empty.button_text"
          href={openSeaCollectionUrlsQuery.data?.pirate.root ?? ''}
          actions={pirateActions}
        />
      );
    }

    if (activeTab === 'ship' && shipsQuery.data) {
      return shipsQuery.data.length > 0 ? (
        <TokenContainer title={t(`home.token.${activeTab}.title`)} subtitle={t(`home.token.${activeTab}.subtitle`)}>
          {shipsQuery.data.map(([id, quantity]) => (
            <ShipTokenCard key={id} quantity={quantity} id={Number(id)} />
          ))}
        </TokenContainer>
      ) : (
        <EmptyToken
          text="home.token.ship.empty.text"
          buttonText="home.token.ship.empty.button_text"
          href={openSeaCollectionUrlsQuery.data?.ship.root ?? ''}
        />
      );
    }

    if (activeTab === 'item' && itemsQuery.data) {
      return itemsQuery.data.length > 0 ? (
        <TokenContainer title={t(`home.token.${activeTab}.title`)} subtitle={t(`home.token.${activeTab}.subtitle`)}>
          {itemsQuery.data.map(([id, quantity]) => (
            <ItemTokenCard key={id} quantity={quantity} id={Number(id)} />
          ))}
        </TokenContainer>
      ) : (
        <EmptyToken
          text="home.token.item.empty.text"
          buttonText="home.token.item.empty.button_text"
          href={openSeaCollectionUrlsQuery.data?.item.root ?? ''}
        />
      );
    }

    if (activeTab === 'chest' && chestsQuery.data) {
      return chestsQuery.data.length > 0 ? (
        <TokenContainer title={t(`home.token.${activeTab}.title`)} subtitle={t(`home.token.${activeTab}.subtitle`)}>
          {chestsQuery.data.map(([id]) => (
            <ChestTokenCard key={id} id={id} />
          ))}
        </TokenContainer>
      ) : (
        <EmptyToken
          text="home.token.chest.empty.text"
          buttonText="home.token.chest.empty.button_text"
          href={openSeaCollectionUrlsQuery.data?.chest.root ?? ''}
        />
      );
    }

    return <Spinner />;
  }, [
    activeTab,
    piratesQuery.data,
    shipsQuery.data,
    itemsQuery.data,
    chestsQuery.data,
    t,
    pirateActions,
    openSeaCollectionUrlsQuery.data?.pirate.root,
    openSeaCollectionUrlsQuery.data?.ship.root,
    openSeaCollectionUrlsQuery.data?.item.root,
    openSeaCollectionUrlsQuery.data?.chest.root,
  ]);

  return (
    <Screen className="flex w-full flex-col items-center justify-start space-y-16" title={t('home.title')}>
      <div className="flex h-16 w-full flex-row items-center justify-between space-x-6 md:justify-center md:space-x-12">
        {tabs.map((tab) => (
          <button onClick={() => setActiveTab(tab)} key={tab} type="button" aria-label={tab}>
            <img
              src={`/assets/images/placeholders/${tab}.png`}
              className={clsx(
                'aspect-square transition-all duration-500',
                activeTab === tab ? 'h-16 opacity-100 md:h-24' : 'h-12 opacity-60',
              )}
              alt={tab}
            />
          </button>
        ))}
      </div>

      <div className="flex w-full flex-col items-start justify-start space-y-16">
        {content}
        <MintModal tokenGenre="pirate" isOpen={mintModalIsOpen} onClose={() => setMintModalIsOpen(false)} />
      </div>
    </Screen>
  );
};

export default HomeScreen;
