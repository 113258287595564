import React, { FC } from 'react';

import { useGetItemTokenInfoQuery } from '../../blockchain/hooks/useGetTokenInfoQuery.hook.ts';
import TokenCardWithAttributeModal from './TokenCardWithAttributeModal.component.tsx';

type Props = {
  id: number;
  quantity?: number;
  onClick?: () => void;
};

const ItemTokenCard: FC<Props> = ({ id, quantity, onClick }) => {
  const tokenQuery = useGetItemTokenInfoQuery(id);

  return (
    <TokenCardWithAttributeModal
      tokenGenre="item"
      token={tokenQuery.data}
      id={id}
      quantity={quantity}
      onClick={onClick}
    />
  );
};

export default ItemTokenCard;
