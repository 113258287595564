import React, { FC } from 'react';

type Props = {
  value: number;
  min: number;
  max: number;
  label?: string;
  onChangeValue?: (value: number) => void;
};

const Slider: FC<Props> = ({ value, onChangeValue, min, max, label }) => (
  <div className="flex w-full items-center space-x-4 ">
    {label && <span className="text-white">{label}</span>}
    <input
      type="range"
      min={min}
      max={max}
      value={value}
      onChange={(element) => {
        if (onChangeValue) onChangeValue(parseInt(element.target.value, 10));
      }}
      className="range-lg h-4 grow appearance-none rounded-lg bg-white bg-opacity-30 accent-brand"
    />
    <span className="text-white">{value}</span>
  </div>
);

export default Slider;
