/* https://opensea.io/collection/seven-seas-pirates */

import { Wallet } from '../types/auth.types.ts';
import { CollectionUrls } from '../types/opensea.types.ts';
import { MAINNET_ID } from './contracts.util.ts';

// eslint-disable-next-line import/prefer-default-export
export const getCollectionUrls = async (wallet: Wallet): Promise<CollectionUrls> => {
  if (wallet.networkId === MAINNET_ID) {
    return {
      pirate: {
        root: 'https://opensea.io/collection/seven-seas-pirates',
        itemPath: 'https://opensea.io/assets/matic/0x6abb93ef55265a373b0143952c5f4749a0205116',
      },
      ship: {
        root: 'https://opensea.io/collection/seven-seas-ships',
        itemPath: 'https://opensea.io/assets/matic/0x02887986ae922a743ba7c9ad6598649b8cd147c9',
      },
      item: {
        root: 'https://opensea.io/collection/seven-seas-items',
        itemPath: 'https://opensea.io/assets/matic/0x0d410c4fde908e045964d4bc88b5c9863a44be75',
      },
      chest: {
        root: 'https://opensea.io/fr/collection/seven-seas-mystery-chests',
        itemPath: 'https://opensea.io/fr/assets/matic/0xbb7b06cc06df69ec8a9dace947f80bd03af2972e',
      },
    };
  }
  return {
    pirate: {
      root: 'https://testnets.opensea.io/collection/unidentified-contract-b4ce4242-cb02-4b03-b588-38f6',
      itemPath: 'https://testnets.opensea.io/assets/amoy/0x4f1ee9464727c0dad24c85971b810fb628e254e3',
    },
    ship: {
      root: 'https://testnets.opensea.io/collection/unidentified-contract-8000583c-4ff9-488e-84a5-cceb',
      itemPath: 'https://testnets.opensea.io/assets/amoy/0xcaabd63d967fb926fde643ad2e09ee4bcf19fc7f',
    },
    item: {
      root: 'https://testnets.opensea.io/collection/unidentified-contract-0658cfac-97a1-4bc0-8875-4917',
      itemPath: 'https://testnets.opensea.io/assets/amoy/0x0d410c4fde908e045964d4bc88b5c9863a44be75',
    },
    chest: {
      root: 'https://testnets.opensea.io/collection/mysterychest-2',
      itemPath: 'https://testnets.opensea.io/assets/amoy/0x0daf5fdc75316c1aff4650bf3061b2473d215af5',
    },
  };
};
