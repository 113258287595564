/* eslint-disable max-len */
import React, { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../app/components/Button.component.tsx';
import Screen from '../../app/components/Screen.component.tsx';
import useAuth from '../../blockchain/hooks/useAuth.hook.ts';
import Modal from '../../app/components/Modal.component.tsx';
import { ConnectWalletError } from '../../blockchain/types/auth.types.ts';
import { MAINNET_ID, TESTNET_ID } from '../../blockchain/utils/contracts.util.ts';

const LandingScreen: FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [authError, setAuthError] = useState<unknown | null>(null);

  return (
    <Screen className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center space-y-16 md:space-y-24">
        <div className="flex flex-col items-center justify-center space-y-8 md:space-y-12">
          <img src="/assets/images/jolly-roger@full.png" alt={t('layout.icon.app')} className="w-24 md:w-36 2xl:w-44" />
          <h1 className="text-center font-heading text-5xl text-brand 2xl:text-7xl">{t('landing.title')}</h1>
          <h2 className="text-center text-xl text-gray-400 lg:text-2xl">{t('landing.subtitle')}</h2>
        </div>
        <Button
          size="large"
          text={t('landing.connect_wallet')}
          onClick={() => {
            auth.connectToWallet().catch((error) => {
              setAuthError(error);
            });
          }}
        />
      </div>

      {/* Connection error */}
      <Modal isOpen={authError !== null} onClose={() => setAuthError(null)}>
        <div className="flex flex-col items-center space-y-4">
          <p className="font-heading text-4xl text-brand">{t('landing.error.title')}</p>
          <div className="flex flex-col items-center space-y-2 text-center text-white">
            {authError === ConnectWalletError.NO_WALLET_AVAILABLE ? (
              <>
                <p>
                  {t('landing.error.no_wallet.intro')}
                  &nbsp;
                  <a target="_blank" href="https://metamask.io/download/" rel="noreferrer" className="text-brand">
                    {t('landing.error.no_wallet.wallets.metamask')}
                  </a>
                  ,&nbsp;
                  <a target="_blank" href="https://trustwallet.com/" rel="noreferrer" className="text-brand">
                    {t('landing.error.no_wallet.wallets.trust_wallet')}
                  </a>
                  ,&nbsp;
                  {t('landing.error.no_wallet.wallets.rest')}
                </p>
                <p>{t('landing.error.no_wallet.post_wallet_instruction')}</p>
              </>
            ) : (
              <p>{t('landing.error.unknown')}</p>
            )}
            <p>
              {t('landing.error.help.prompt')}
              &nbsp;
              <a href="https://discord.com/invite/2Hz62BVA6J" target="_blank" rel="noreferrer" className="text-brand">
                {t('landing.error.help.discord')}
              </a>
            </p>
          </div>
        </div>
      </Modal>

      {/* Wrong network error */}
      <Modal
        isOpen={!!auth.wallet && ![MAINNET_ID, TESTNET_ID].includes(auth.wallet.networkId)}
        hideCloseButton
        onClose={() => undefined}
      >
        <div className="flex flex-col items-center space-y-4">
          <p className="font-heading text-4xl text-brand">{t('landing.error.wrong_chain.title')}</p>
          <div className="flex flex-col items-center space-y-2 text-center text-white">
            {t('landing.error.wrong_chain.text')}
          </div>
        </div>
        {window.ethereum && 'isMetaMask' in window.ethereum && (
          <div className="mt-12 flex w-full flex-row items-center justify-center">
            <Button
              onClick={async () => {
                try {
                  if (window.ethereum) {
                    await window.ethereum.request({
                      method: 'wallet_switchEthereumChain',
                      params: [{ chainId: '0x89' }],
                    });
                  }
                } catch (e) {
                  console.error(e); // eslint-disable-line no-console
                }
              }}
              text={t('landing.error.wrong_chain.action')}
            />
          </div>
        )}
      </Modal>
    </Screen>
  );
};

export default LandingScreen;
