import React, { FC } from 'react';

type Props = {
  src: string;
  alt: string;
  href?: string | null;
};

const LayoutIcon: FC<Props> = ({ src, alt, href }) => {
  const img = <img src={src} alt={alt} className="h-8" />;

  if (href) {
    return (
      <a href={href} title={alt} target="_blank" rel="noreferrer">
        {img}
      </a>
    );
  }

  return img;
};

LayoutIcon.defaultProps = {
  href: null,
};

export default LayoutIcon;
