import React, { FC } from 'react';

type Props = {
  onClick?: () => void;
};

const CloseButton: FC<Props> = ({ onClick = () => undefined }) => (
  <button type="button" onClick={() => onClick()} aria-label="Close">
    <svg
      className="h-6 w-6 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
);

export default CloseButton;
