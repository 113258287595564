import React, { FC, PropsWithChildren } from 'react';
import Button from '../../app/components/Button.component.tsx';

type Props = PropsWithChildren & {
  title: string;
  subtitle: string;
  actions?: { title: string; onClick: () => void; highlight?: boolean }[];
};

const TokenContainer: FC<Props> = ({ children, title, subtitle, actions }) => (
  <div className="space-y-4">
    <h1 className="font-heading text-6xl text-brand">{title}</h1>
    {actions?.map((action) => (
      <Button
        key={action.title}
        text={action.title}
        size="small"
        className="my-2"
        onClick={action.onClick}
        highlight={action.highlight}
      />
    ))}
    <p className="mb-12 text-lg text-gray-200">{subtitle}</p>
    <div
      // eslint-disable-next-line prettier/prettier
      className="animate-fade-in grid w-full grid-cols-2 gap-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6"
    >
      {children}
    </div>
  </div>
);

export default TokenContainer;
