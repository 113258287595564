import React, { FC } from 'react';

import { useGetShipTokenInfoQuery } from '../../blockchain/hooks/useGetTokenInfoQuery.hook.ts';
import TokenCardWithAttributeModal from './TokenCardWithAttributeModal.component.tsx';

type Props = {
  id: number;
  quantity?: number;
  mini?: boolean;
  onClick?: () => void;
};

const ShipTokenCard: FC<Props> = ({ id, mini = false, quantity, onClick }) => {
  const tokenQuery = useGetShipTokenInfoQuery(id);

  return (
    <TokenCardWithAttributeModal
      tokenGenre="ship"
      token={tokenQuery.data}
      id={id}
      mini={mini}
      quantity={quantity}
      onClick={onClick}
    />
  );
};

export default ShipTokenCard;
