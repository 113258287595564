import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import Button from './Button.component.tsx';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  hideCloseButton?: boolean;
};

const Modal: FC<PropsWithChildren<Props>> = ({ isOpen, onClose, className, children, hideCloseButton = false }) => {
  const { t } = useTranslation();

  if (isOpen) {
    return createPortal(
      <div className="pointer-event-none fixed left-0 right-0 top-0 z-50 flex h-full h-screen w-full items-center justify-center overflow-x-hidden overflow-y-hidden bg-black bg-opacity-50 p-8">
        <div
          className={clsx(
            'max-h-full w-full max-w-4xl space-y-12 overflow-auto rounded-xl bg-black p-12 md:w-auto',
            className,
          )}
        >
          <div className="h-full">{children}</div>
          {!hideCloseButton && (
            <div className="flex flex-row justify-center">
              <Button onClick={() => onClose()} text={t('app.modal.close')} />
            </div>
          )}
        </div>
      </div>,
      document.body,
    );
  }

  return null;
};

export default Modal;
