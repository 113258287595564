import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Locales
import en from '../locales/en.json';

// eslint-disable-next-line import/prefer-default-export
export const initI18n = () => {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
    },
    lng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
};
