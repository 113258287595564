import { useMutation } from 'react-query';

import { mintPirate } from '../utils/contracts.util.ts';
import useAuth from './useAuth.hook.ts';

const useMintPirateMutation = () => {
  const auth = useAuth();

  return useMutation({
    mutationFn: () => mintPirate(auth.wallet!),
  });
};

export default useMintPirateMutation;
