import * as ethers from 'ethers';

import erc20Abi from '../abis/erc20.abi.json';
import erc721Abi from '../abis/erc721.abi.json';
import tavernAbi from '../abis/tavern.abi.json';
import mysteryChestAbi from '../abis/mystery-chest.abi.json';
import gold from '../abis/gold.abi.json';
import gameObject from '../abis/game-object.abi.json';

import { FungibleTokenGenre, NonFungibleTokenGenre, TokenGenre } from '../types/token.types.ts';
import { Wallet } from '../types/auth.types.ts';

export const MAINNET_ID = 137;
export const TESTNET_ID = 80002;

const GAS_INCREASE_RATIO = 1.25;

const getTimeStamp = async (wallet: Wallet): Promise<number> => {
  const block = await wallet.provider.getBlock(await wallet.provider.getBlockNumber());
  if (block) {
    return block.timestamp;
  }
  throw Error('Failed to retrieve timestamp from block');
};

export const getSmartContractAddressForTokenGenre = async (wallet: Wallet, tokenGenre: TokenGenre): Promise<string> => {
  const contracts = wallet.networkId === TESTNET_ID // eslint-disable-line prettier/prettier
      ? {
          pirate: '0x4f1Ee9464727C0daD24C85971B810fB628E254e3',
          item: '0x0d410C4FDe908e045964d4bc88b5C9863A44bE75',
          ship: '0xCAabD63D967fB926fDe643AD2e09eE4BcF19fC7f',
          gold: '0x9a6f37A51D5942CF508E6ebf28FDB1b1177D537C',
          rum: '0xAF7Bc75f5A13052A1e4C23B4d4400FC8365A1395',
          chest: '0x0DAF5FDC75316C1aFf4650bF3061b2473D215af5',
        }
      : {
          pirate: '0x6ABb93EF55265a373b0143952C5f4749A0205116',
          item: '0x0d410C4FDe908e045964d4bc88b5C9863A44bE75',
          ship: '0x02887986Ae922A743bA7C9ad6598649b8CD147c9',
          gold: '0xe730341CB1129bb0a8e0011c31dAb74fae147E43',
          rum: '0xeb0C08dEe0cd92b6260e0c788AD2a2FaDF889356',
          chest: '0xBb7B06cc06dF69eC8a9dAce947F80BD03af2972E',
        };

  return contracts[tokenGenre];
};

export const getTavernContract = async (wallet: Wallet): Promise<ethers.Contract> => {
  const address = wallet.networkId === TESTNET_ID // eslint-disable-line prettier/prettier
      ? '0x20B081AD28527eA1853e85e0Da8525C5240894DF'
      : '0x90d1457738FC02F0b4e9f8832748B70B5551f9b6';
  return new ethers.Contract(address, tavernAbi, wallet.provider);
};

export const getMaticBalance = (wallet: Wallet) => wallet.provider.getBalance(wallet.address);

export const getFungibleTokenBalance = async (wallet: Wallet, token: FungibleTokenGenre): Promise<bigint> => {
  const contract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, token),
    erc20Abi,
    wallet.provider,
  );
  return contract.balanceOf(wallet.address);
};

export const getOwnedMysteryChests = async (wallet: Wallet): Promise<[number, number][]> => {
  const contract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, 'chest'),
    mysteryChestAbi,
    wallet.provider,
  );

  const balance = await getFungibleTokenBalance(wallet, 'chest');
  const lastMintedTokenId = Number(await contract.lastMintedTokenId());
  const ownedMysteryChests: [number, number][] = [];

  for (let candidateId = 1; candidateId <= lastMintedTokenId && ownedMysteryChests.length < balance; candidateId += 1) {
    const ownerOfCandidate = await contract.ownerOf(candidateId).catch(() => null); // eslint-disable-line no-await-in-loop
    if (ownerOfCandidate === wallet.address) {
      ownedMysteryChests.push([candidateId, 1]);
    }
  }

  return ownedMysteryChests;
};

export const getOwnedNonFungibleTokens = async (
  wallet: Wallet,
  token: NonFungibleTokenGenre,
): Promise<[number, number][]> => {
  if (token === 'chest') {
    return getOwnedMysteryChests(wallet);
  }

  const contract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, token),
    erc721Abi,
    wallet.provider,
  );

  return contract.maxMintableId().then((maxMintableId: bigint) => {
    const max = Number(maxMintableId) + 1;
    const addresses = Array(max).fill(wallet.address);
    const ids = Array(max)
      .fill(0)
      .map((_, index) => index);

    return contract.balanceOfBatch(addresses, ids).then((result) =>
      result.reduce((ownedIds: number[], current: bigint, index: number) => {
        if (current > 0) {
          return [...ownedIds, [index, Number(current)]];
        }
        return ownedIds;
      }, []),
    );
  });
};

export const getChestTokenInfo = async (wallet: Wallet, id: number): Promise<unknown> => {
  const contract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, 'chest'),
    mysteryChestAbi,
    wallet.provider,
  );

  const callback = (uri: string) => {
    const ipfsId = uri.split('://')[1];
    return fetch(`https://ipfs.io/ipfs/${ipfsId}`).then((res) => res.json());
  };

  return contract.tokenURI(id).then(callback);
};

export const getTokenInfo = async (wallet: Wallet, id: number, token: NonFungibleTokenGenre): Promise<unknown> => {
  const baseUrl = wallet.networkId === TESTNET_ID ? 'https://testnet-api.7seas.quest' : 'https://api.7seas.quest';
  return fetch(`${baseUrl}/${token}/${id}`)
    .then((res) => res.json())
    .then((payload) => {
      if (typeof payload === 'object') {
        return {
          ...payload,
          image: `${baseUrl}/${token}/${id}/image`,
        };
      }
      return payload;
    });
};

export const getChestUnlockTime = async (wallet: Wallet, id: number): Promise<number> => {
  const contract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, 'chest'),
    mysteryChestAbi,
    wallet.provider,
  );
  return contract.unlockTime(id);
};

export const openMysteryChest = async (
  wallet: Wallet,
  id: number,
): Promise<{ pirate1: number; pirate2: number; ship: number }> => {
  const pirateContract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, 'pirate'),
    erc721Abi,
    wallet.provider,
  );
  const shipContract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, 'ship'),
    erc721Abi,
    wallet.provider,
  );

  const signer = await wallet.provider.getSigner();
  const chestContract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, 'chest'),
    mysteryChestAbi,
    wallet.provider,
  ).connect(signer) as ethers.Contract;

  // eslint-disable-next-line no-async-promise-executor
  return new Promise<{ pirate1: number; pirate2: number; ship: number }>(async (resolve, reject) => {
    let pirate1: number | undefined;
    let pirate2: number | undefined;
    let ship: number | undefined;

    const onCallbackEnd = () => {
      if (typeof pirate1 !== 'undefined' && typeof pirate2 !== 'undefined' && typeof ship !== 'undefined') {
        resolve({ pirate1, pirate2, ship });
      }
    };

    setTimeout(() => {
      reject(new Error('Timeout'));
    }, 60_000);

    await shipContract.on('TransferSingle', (_operator, _from, to, itemId) => {
      if (to !== wallet.address) return;
      if (typeof ship === 'undefined') ship = Number(itemId);
      onCallbackEnd();
    });

    await pirateContract.on('TransferBatch', (_operator, _from, to, [itemId1, itemId2]) => {
      if (to !== wallet.address) return;
      if (typeof pirate1 === 'undefined' || typeof pirate2 === 'undefined') {
        pirate1 = Number(itemId1);
        pirate2 = Number(itemId2);
      }
      onCallbackEnd();
    });

    const gas = BigInt(Math.floor(Number(await chestContract.open.estimateGas(id)) * GAS_INCREASE_RATIO));
    await chestContract.open(id, { gasLimit: gas });
  }).finally(() => {
    shipContract.removeAllListeners();
    pirateContract.removeAllListeners();
  });
};

export const transferNonFungibleToken = async (
  wallet: Wallet,
  token: NonFungibleTokenGenre,
  recipient: string,
  id: number,
  quantity: number,
): Promise<ethers.TransactionReceipt | null> => {
  const signer = await wallet.provider.getSigner();
  const tokenContract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, token),
    gameObject,
    wallet.provider,
  ).connect(signer) as ethers.Contract;

  const tx = (await tokenContract.safeTransferFrom(
    wallet.address,
    recipient,
    id,
    quantity,
    '0x',
  )) as ethers.TransactionResponse;
  return tx.wait();
};

export const getPiratePrice = async (wallet: Wallet): Promise<bigint> => {
  const tavernContract = await getTavernContract(wallet);
  return tavernContract.piratePrice();
};

export const mintPirate = async (wallet: Wallet): Promise<number> => {
  const signer = await wallet.provider.getSigner();
  const tavernContract = (await getTavernContract(wallet)).connect(signer) as ethers.Contract;
  const pirateContract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, 'pirate'),
    gameObject,
    wallet.provider,
  );
  const goldContract = new ethers.Contract(
    await getSmartContractAddressForTokenGenre(wallet, 'gold'),
    gold,
    wallet.provider,
  );

  // Generate permit signature
  const nonce = await goldContract.nonces(wallet.address);
  const deadline = (await getTimeStamp(wallet)) + 3600; // 1 hour from now
  const types = {
    Permit: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ],
  };
  const domain = {
    name: await goldContract.name(),
    version: '1',
    chainId: wallet.networkId,
    verifyingContract: await goldContract.getAddress(),
  };
  const message = {
    owner: wallet.address,
    spender: await tavernContract.getAddress(),
    value: await getPiratePrice(wallet),
    nonce,
    deadline,
  };
  const signature = await signer.signTypedData(domain, types, message);
  const { v, r, s } = ethers.Signature.from(signature);

  try {
    return new Promise((resolve, reject) => {
      async function buy() {
        try {
          const gas = BigInt(
            Math.floor(
              // eslint-disable-next-line operator-linebreak
              Number(await tavernContract.buyPirateWithPermit.estimateGas(BigInt(1), deadline, v, r, s)) *
                GAS_INCREASE_RATIO,
            ),
          );
          await pirateContract.on('TransferSingle', (_operator, _from, to, itemId) => {
            if (to === wallet.address) {
              resolve(Number(itemId));
            }
          });
          await tavernContract.buyPirateWithPermit(BigInt(1), deadline, v, r, s, { gasLimit: gas });

          setTimeout(() => {
            reject(new Error('Timeout'));
          }, 60_000);
        } catch (error) {
          reject(error);
        }
      }

      buy();
    });
  } finally {
    pirateContract.removeAllListeners();
  }
};
