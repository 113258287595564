import React, { FC } from 'react';

type Props = {
  text?: string;
  placeholder?: string;
  onChangeText?: (text: string) => void;
};

const TextInput: FC<Props> = ({ text, onChangeText, placeholder }) => (
  <input
    type="text"
    className="appearance-none rounded-md bg-white bg-opacity-30 p-2 text-white"
    value={text}
    placeholder={placeholder}
    onChange={(element) => {
      if (onChangeText) onChangeText(element.target.value);
    }}
  />
);

export default TextInput;
