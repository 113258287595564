import { createContext } from 'react';
import { AuthContextType } from '../types/auth.types.ts';

const AuthContext = createContext<AuthContextType>({
  wallet: null,
  isBooting: true,
  connectToWallet: () => Promise.resolve(),
  disconnectWallet: () => undefined,
});

export default AuthContext;
