import React, { FC } from 'react';

import clsx from 'clsx';
import { Token } from '../../blockchain/types/token.types.ts';

type Props = {
  id: number;
  token: Token | undefined;
  onClick?: () => void;
  large?: boolean;
  mini?: boolean;
  quantity?: number;
};

const TokenCard: FC<Props> = ({ id, token, onClick, large = false, mini = false, quantity = 1 }) => {
  const content = (
    <div className="relative">
      {token ? (
        <div className="flex w-full flex-row justify-center overflow-hidden rounded-t-lg bg-black">
          <img
            src={token.image}
            className={clsx('md:h-40 md:w-40', mini ? 'h-20 w-20' : 'h-32 w-32', large && 'md:h-48 md:w-48')}
            crossOrigin="anonymous"
            alt={`#${id}`}
          />
        </div>
      ) : (
        <div
          className={clsx(
            'overflow-hidden rounded-t-lg bg-black md:h-40 md:w-40',
            mini ? 'h-20 w-20' : 'h-32 w-32',
            large && 'md:h-48 md:w-48',
          )}
        />
      )}
      <div
        className={clsx(
          'flex items-center justify-center overflow-hidden rounded-b-lg bg-gray-500 bg-opacity-50 md:h-10',
          mini ? 'h-6' : 'h-8',
          large && 'md:h-12',
        )}
      >
        <span className={clsx(mini ? 'text-sm' : 'text-md', 'text-white md:text-lg')}>{`#${id}`}</span>
      </div>

      {quantity > 1 && (
        <div className="absolute bottom-0 right-0 flex h-8 w-8 translate-x-1/3 translate-y-1/3 items-center justify-center rounded-full bg-brand">
          <span className="text-center text-lg text-white">{quantity}</span>
        </div>
      )}
    </div>
  );

  return onClick ? (
    <button
      type="button"
      className={clsx(
        'duration-250 transition-transform md:w-40',
        onClick === undefined ? '' : 'hover:scale-105',
        mini ? 'w-20' : 'w-32 ',
        large && 'md:w-48',
      )}
      style={{ cursor: onClick === undefined ? 'default' : 'pointer' }}
      onClick={onClick ? () => onClick() : undefined}
    >
      {content}
    </button>
  ) : (
    <div className={clsx('md:w-40', mini ? 'w-20' : 'w-32 ', large && 'md:w-48')}>{content}</div>
  );
};

export default TokenCard;
