import React, { FC, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import TokenCard from './TokenCard.component.tsx';
import Modal from './Modal.component.tsx';
import { NonFungibleTokenGenre, Token } from '../../blockchain/types/token.types.ts';
import Button from './Button.component.tsx';
import TransferModal from './TransferModal.component.tsx';
import CloseButton from './CloseButton.component.tsx';

type Props = {
  id: number;
  tokenGenre: NonFungibleTokenGenre;
  token: Token | undefined;
  quantity?: number;
  mini?: boolean;
  onClick?: () => void;
  collectionUrl?: string;
};

const ProgressBar = ({ value }: { value: number }) => {
  const displayedValue = Math.max(Math.min(value, 100), 2);

  return (
    <div className="h-2 w-full overflow-hidden rounded-lg bg-gray-800">
      <div style={{ width: `${displayedValue}%` }} className="h-full bg-brand" />
    </div>
  );
};

const TokenCardWithAttributeModal: FC<Props> = ({
  id,
  token,
  mini = false,
  quantity,
  onClick,
  collectionUrl,
  tokenGenre,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const attributesToDisplay = useMemo(() => {
    if (!token) {
      return [];
    }

    return Object.keys(token.attributes)
      .filter((key) => typeof token.attributes[key as keyof typeof token.attributes] === 'number')
      .map((key) => ({
        key,
        value: token.attributes[key as keyof typeof token.attributes] as number,
      }));
  }, [token]);

  return (
    <>
      <TokenCard
        id={id}
        token={token}
        onClick={() => (onClick ? onClick() : setShowModal(true))}
        mini={mini}
        quantity={quantity}
      />
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <div className="flex h-6 w-full flex-row justify-end">
          <CloseButton onClick={() => setShowModal(false)} />
        </div>
        <div className="flex w-full flex-col items-center space-y-8 md:flex-row md:space-x-16 md:space-y-0">
          <div className="flex flex-col items-center space-y-4">
            <TokenCard token={token} id={id} large quantity={quantity} />
            <div className="flex items-start p-8 md:flex-col">
              <Button
                text={t('home.token.actions.transfer')}
                image="/assets/images/send.png"
                onClick={() => setShowTransferModal(true)}
                className="bg-transparent opacity-60 hover:bg-transparent hover:opacity-100"
              />
              <a href={collectionUrl} target="_blank" rel="noreferrer" aria-label={t('home.token.actions.trade')}>
                <Button
                  text={t('home.token.actions.trade')}
                  image="/assets/images/open-sea.png"
                  className="bg-transparent opacity-60 hover:bg-transparent hover:opacity-100"
                />
              </a>
            </div>
          </div>
          <div className="flex w-full grow flex-col space-y-8 md:w-auto md:min-w-96">
            {attributesToDisplay.map(({ key, value }) => (
              <div key={key} className="flex-col space-y-4">
                <div className="flex w-full flex-row items-center justify-between space-x-4">
                  <span className="font-heading text-2xl text-brand md:text-3xl">
                    {t(`home.token.attribute.${key}`)}
                  </span>
                  <span className="font-bold text-white md:text-lg">{value}</span>
                </div>
                <ProgressBar value={value} />
              </div>
            ))}
          </div>
        </div>
      </Modal>
      {token && (
        <TransferModal
          isOpen={showTransferModal}
          token={token}
          tokenGenre={tokenGenre}
          quantity={quantity ?? 1}
          onClose={() => setShowTransferModal(false)}
        />
      )}
    </>
  );
};

export default TokenCardWithAttributeModal;
