import { useQuery } from 'react-query';

import { getFungibleTokenBalance } from '../utils/contracts.util.ts';
import useAuth from './useAuth.hook.ts';

const useFungibleTokenBalanceQuery = (token: 'rum' | 'gold' | 'chest') => {
  const auth = useAuth();

  return useQuery(['fungible-token-balance', auth.wallet, token], () => getFungibleTokenBalance(auth.wallet!, token), {
    enabled: !!auth.wallet,
    // Refresh every 30 secs
    refetchInterval: 30000,
  });
};

export default useFungibleTokenBalanceQuery;
